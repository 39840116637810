import {Injectable} from '@angular/core';
import {
  Dataset,
  ReportFilter,
  Scope,
  ScopeField,
  Widget,
  WidgetConfiguration,
} from '../models';
import {AbstractControl} from '@angular/forms';

export let uniqueMetrics = new Set<string>();
export let widgetMetrics: ScopeField[] = [];

@Injectable({
  providedIn: 'root',
})
export class ScopeService {
  scopes: Scope[] = [];
  scopeId: string;
  metricTypes: string[] = [
    'currency',
    'percentage',
    'decimal',
    'integer',
    'time',
  ];

  public setWidgetFields(widgetDataset: Dataset): [Scope[], ScopeField[]] {
    this.scopes = widgetDataset.scopes || [];
    const fields: ScopeField[] = [];

    const allScopeIds: string[] = this.scopes.map((scope) => scope.id!);

    // from widgetDataset
    for (const scope of this.scopes) {
      // from local array
      for (const scopeField of scope.fields) {
        const firstMatch = fields.find((elem) => elem.id === scopeField.id);

        if (!firstMatch) {
          const newScopeField = {...scopeField, matchingScopes: [scope.id!]};
          fields.push(newScopeField);
        } else {
          // Just add this matching scope.
          firstMatch.matchingScopes?.push(scope.id!);
        }
      }
    }

    for (const scopeField of fields) {
      // Add all the scopes to which this field does not belong.
      scopeField.excludedScopes = allScopeIds.filter(
        (id) => !(scopeField.matchingScopes?.includes(id) ?? false)
      );
    }

    return [this.scopes, fields];
  }

  /**
   *
   * @param widgetConfiguration
   * @returns [filteredDimensions, filteredMetrics, filteredFields]
   */
  public filterMetricsAndDimensionsByFields(
    widgetConfiguration: WidgetConfiguration
  ): [ScopeField[], ScopeField[]] {
    let filteredDimensions: ScopeField[] = [];
    let filteredMetrics: ScopeField[] = [];

    const fieldsNoAdPreview = [...widgetConfiguration.fields].filter(
      (field) => field.dataType !== 'adPreview'
    );

    const fieldsDate = widgetConfiguration.fields.filter(
      (field: ScopeField) => field.dataType === 'date'
    );

    const widgetMetricsNoAdPreview = widgetConfiguration.widget.metrics
      ? [...widgetConfiguration.widget.metrics].filter(
          (field) => field.dataType !== 'adPreview'
        )
      : [];

    if (widgetConfiguration.fields !== undefined) {
      // Initialize filteredDimensions to an empty array
      switch (widgetConfiguration.widget.widgetType) {
        case 'timeline':
          filteredDimensions = fieldsDate.filter((field: ScopeField) =>
            widgetConfiguration.search
              ? field.name.toLowerCase().includes(widgetConfiguration.search) &&
                widgetConfiguration.widget.dimensions &&
                !widgetConfiguration.widget.dimensions.some(
                  (f) => f.id === field.id
                )
              : widgetConfiguration.widget.dimensions &&
                !widgetConfiguration.widget.dimensions.some(
                  (f) => f.id === field.id
                )
          );
          break;
        case 'geoHeatMap':
          filteredDimensions = [
            ...widgetConfiguration.locationDimension.filter(
              (field: ScopeField) =>
                widgetConfiguration.search
                  ? field.name
                      .toLowerCase()
                      .indexOf(widgetConfiguration.search) > -1 &&
                    widgetConfiguration.widget.dimensions &&
                    !widgetConfiguration.widget.dimensions.some(
                      (f) => f.id === field.id
                    )
                  : widgetConfiguration.widget.dimensions &&
                    !widgetConfiguration.widget.dimensions.some(
                      (f) => f.id === field.id
                    )
            ),
          ];
          break;
        case 'table':
        case 'gallery':
          filteredDimensions = widgetConfiguration.fields.filter(
            (field: ScopeField) =>
              widgetConfiguration.search
                ? field.name.toLowerCase().indexOf(widgetConfiguration.search) >
                    -1 &&
                  field.dataType &&
                  !this.metricTypes.includes(field.dataType) &&
                  widgetConfiguration.widget.dimensions &&
                  !widgetConfiguration.widget.dimensions.some(
                    (f) => f.id === field.id
                  )
                : field.dataType &&
                  !this.metricTypes.includes(field.dataType) &&
                  widgetConfiguration.widget.dimensions &&
                  !widgetConfiguration.widget.dimensions.some(
                    (f) => f.id === field.id
                  )
          );
          break;
        default:
          filteredDimensions = fieldsNoAdPreview.filter((field: ScopeField) =>
            widgetConfiguration.search
              ? field.name.toLowerCase().indexOf(widgetConfiguration.search) >
                  -1 &&
                field.dataType &&
                !this.metricTypes.includes(field.dataType) &&
                widgetConfiguration.widget.dimensions &&
                !widgetConfiguration.widget.dimensions.some(
                  (f) => f.id === field.id
                )
              : field.dataType &&
                !this.metricTypes.includes(field.dataType) &&
                widgetConfiguration.widget.dimensions &&
                !widgetConfiguration.widget.dimensions.some(
                  (f) => f.id === field.id
                )
          );
          break;
      }

      // Filter metrics
      filteredMetrics = fieldsNoAdPreview.filter((field: ScopeField) =>
        widgetConfiguration.search
          ? field.name.toLowerCase().indexOf(widgetConfiguration.search) > -1 &&
            field.dataType &&
            this.metricTypes.includes(field.dataType) &&
            !widgetMetricsNoAdPreview.some((f) => f.id === field.id)
          : widgetConfiguration.allowDuplicate
            ? field.dataType && this.metricTypes.includes(field.dataType)
            : field.dataType &&
              this.metricTypes.includes(field.dataType) &&
              !widgetMetricsNoAdPreview.some((f) => f.id === field.id)
      );
    }

    filteredMetrics.forEach((fm) => {
      if (fm.isDisabled) {
        fm.incompatibleWithFieldsTooltip = this.showTooltip(fm);
      }
    });

    // set isDimension to true for filteredDimensions
    filteredDimensions = filteredDimensions.map((el) => {
      el.incompatibleWithFieldsTooltip = this.showTooltip(el);
      el.isDimension = true;
      return el;
    });

    return [filteredDimensions, filteredMetrics];
  }

  public onFieldSelectionChanged(
    widget: Widget,
    fields: ScopeField[],
    pageFilters?: ReportFilter[]
  ): [ScopeField[], string] {
    let availableScopes: string[] = [];
    const dimensions: ScopeField[] = widget.dimensions || [];
    const metrics: ScopeField[] = widget.metrics || [];
    const filters: ReportFilter[] = widget.filters || [];

    if (dimensions.length > 0 || metrics.length > 0 || filters.length > 0) {
      let allSelectedFields: ScopeField[] = dimensions.concat(metrics);
      allSelectedFields = this.validateMissingProperties(
        allSelectedFields,
        fields
      );

      if (filters.length > 0) {
        // Extract unique fieldIds from filters and expressions
        const uniqueFieldIds = new Set(
          filters
            .flatMap((filter) =>
              filter.expressions ? filter.expressions : [filter]
            )
            .map((expr) => expr.fieldId)
            .filter(Boolean) // Remove falsy values like undefined or null
        );

        // Filter fields based on unique fieldIds
        const newFields = fields
          .filter((field) => uniqueFieldIds.has(field.id!))
          .map((field) => ({
            ...this.validateMissingProperties([field], fields)[0],
            // Set 'fromFilter' to true if the field is not in allSelectedFields
            fromFilter: !allSelectedFields.some(
              (selectedField) => selectedField.id === field.id
            ),
          }));

        // Add new fields to allSelectedFields
        allSelectedFields = allSelectedFields.concat(newFields);
      } else {
        // If there are no filters, remove all fields with the 'fromFilter' flag set to true
        allSelectedFields = allSelectedFields.filter(
          (field) => !field.fromFilter
        );
      }

      availableScopes = this.getAvailableScope(allSelectedFields, fields);

      // If there are no pageFilters, recalculate the scope for the widget
      if (pageFilters && pageFilters.length > 0) {
        availableScopes = this.checkPageFilterCompatibility(
          pageFilters,
          allSelectedFields,
          fields
        );
      }
    } else {
      fields.forEach((field) => {
        field.isDisabled = false;
        field.incompatibleWithFields = [];
      });
    }

    return [
      this.sortFields(fields),
      this.getScopeBySpecificityLevel(availableScopes),
    ];
  }

  private checkPageFilterCompatibility(
    pageFilters: ReportFilter[],
    allSelectedFields: ScopeField[],
    fields: ScopeField[]
  ): string[] {
    let availableScopes: string[] = this.getAvailableScope(
      allSelectedFields,
      fields
    );

    const fieldsForPage: ScopeField[] = fields.reduce(
      (acc: ScopeField[], value: ScopeField) => {
        return [...acc, value];
      },
      []
    );

    //For every page filter, check whether the filters inside are compatible with the fields.
    // This process is similar to adding a new dimension or metric inside the widget
    pageFilters.forEach((filter) => {
      const matchingField = fieldsForPage.find(
        (field) => field.name === filter.fieldName && !field.isDisabled
      );

      if (matchingField) {
        allSelectedFields = [...allSelectedFields, matchingField];
        availableScopes = this.getAvailableScope(
          allSelectedFields,
          fieldsForPage
        );
      }
    });

    return availableScopes;
  }

  private getAvailableScope(
    allSelectedFields: ScopeField[],
    fields: ScopeField[]
  ): string[] {
    let availableScopes: string[] = [];

    // Extract the matchingScopes arrays for each selected field into a new array
    const matchingScopesArrays = allSelectedFields.map(
      (field) => field.matchingScopes || []
    );

    // Find the intersection of matchingScopes for all selected fields to get the availableScopes
    // Remove duplicates by filtering the array and only keep unique values
    availableScopes =
      matchingScopesArrays
        ?.shift()
        ?.filter((sc: string, index: number, self: string[]) => {
          return (
            matchingScopesArrays.every((msc) => msc.includes(sc)) &&
            self.indexOf(sc) === index
          );
        }) || [];

    fields.forEach((field) => {
      const matchedScopes =
        field.matchingScopes?.filter((element) =>
          availableScopes.includes(element)
        ) || [];
      // Set the isDisabled property of the field based on whether it has any matchedScopes or not
      field.isDisabled = matchedScopes.length === 0;
      field.incompatibleWithFields = [];

      // If the field is disabled, find the incompatible fields
      if (field.isDisabled) {
        const incompatibleFields = allSelectedFields.filter(
          (selectedField) =>
            !selectedField.matchingScopes?.some((selectedScope) =>
              field.matchingScopes?.includes(selectedScope)
            )
        );
        field.incompatibleWithFields = incompatibleFields.map(
          (incompatibleField) => incompatibleField.name
        );
      }
    });

    return availableScopes;
  }

  private sortFields(fields: ScopeField[]): ScopeField[] {
    return fields.sort((a, b) => Number(a.isDisabled) - Number(b.isDisabled));
  }

  private validateMissingProperties(
    selectedFields: Array<ScopeField>,
    allFields: Array<ScopeField>
  ): Array<ScopeField> {
    return selectedFields.map((field) => {
      if (!field.matchingScopes && !field.excludedScopes) {
        const foundField = allFields.find((f) => f.id === field.id);

        if (foundField) {
          return {
            ...field,
            matchingScopes: foundField.matchingScopes,
            excludedScopes: foundField.excludedScopes,
          };
        }
      }

      return field;
    });
  }

  public getScopeBySpecificityLevel(availableScopes: string[]): string {
    let scopeId = '';
    if (availableScopes && availableScopes.length > 0) {
      const scopeScores = this.scopes
        .map((scope) => {
          //the counter is used to know which of the selected dimensions and metrics appears the most on the scopes
          let counter = 0;
          if (availableScopes.includes(scope.id)) {
            counter++;
          }
          //The score is used to know which of the scope is the most specific
          const score = scope.specificityLevel
            ? counter * scope.specificityLevel
            : counter;

          return {scopeId: scope.id, score: score};
        })
        .filter((item) => item.score !== 0);

      // Sorts the scopeScores items in ascending order, with the first item having the lowest specificity.
      scopeScores.sort((a, b) => a.score - b.score);
      if (scopeScores.length > 0) {
        scopeId = scopeScores[0].scopeId;
      }
    }
    return scopeId;
  }

  public showTooltip(field: ScopeField): string {
    let message = '';
    if (field?.incompatibleWithFields?.length) {
      const incompatibleFields: string = field.incompatibleWithFields
        .slice(0, 3)
        .map((field) => `"${field}"`)
        .join(', ');
      message = `This field is not compatible with ${incompatibleFields}`;
    }

    return message;
  }

  public updateWidgetMetricsFromMatches(
    metrics: ScopeField[],
    matches: string[] | AbstractControl[]
  ): ScopeField[] {
    uniqueMetrics = new Set<string>();
    widgetMetrics = [];
    let conditions: AbstractControl[] | string[];

    if (Array.isArray(matches) && matches[0] instanceof AbstractControl) {
      conditions = matches as AbstractControl[];
    } else {
      conditions = matches;
    }

    conditions.forEach((field: AbstractControl | string) => {
      let condition: string;
      let trueValuePlainText = '';
      let falseValuePlainText = '';

      if (field instanceof AbstractControl) {
        condition = field.get('firstCondition')?.value;
        trueValuePlainText = field.get('trueValuePlainText')?.value;
        falseValuePlainText = field.get('falseValuePlainText')?.value;
      } else {
        condition = field;
      }

      if (condition) {
        this.processMatches(condition, metrics);
      }

      if (trueValuePlainText) {
        this.processTextMatches(trueValuePlainText, metrics);
      }

      if (falseValuePlainText) {
        this.processTextMatches(falseValuePlainText, metrics);
      }
    });

    return widgetMetrics;
  }

  private processTextMatches(value: string, metrics: ScopeField[]): void {
    const foundMatches = value.match(
      /{{([A-Za-z][A-Za-z0-9]+)_?([A-Za-z][_A-Za-z0-9]+)}}/g
    );

    if (foundMatches) {
      foundMatches.forEach((matchMetric: string) => {
        this.processMatches(matchMetric, metrics);
      });
    }
  }

  private processMatches(condition: string, metrics: ScopeField[]): void {
    const match = condition.match(
      /([A-Za-z][A-Za-z0-9]*)_([A-Za-z][A-Za-z0-9]*)/
    );

    if (match) {
      const id = match[1];
      const aggregation = match[2];
      const metricIdentifier = `${id}_${aggregation}`;

      // Check if the metric identifier is unique before adding it.
      if (!uniqueMetrics.has(metricIdentifier)) {
        uniqueMetrics.add(metricIdentifier);
        const existingMetric: ScopeField | undefined = metrics.find(
          (metric) => metric.id === id
        );
        // If an existing metric is found, create a copy with updated aggregation.
        if (existingMetric) {
          const metric = {...existingMetric, aggregation};
          widgetMetrics.push(metric);
        }
      }
    }
  }

  public recalculateWidgetScope(
    datasets: Dataset[],
    widget: Widget,
    pageFilters: ReportFilter[]
  ): void {
    const widgetDataset = datasets?.find(
      (dataset) => dataset._id === widget.dataset
    );

    //The widget scope will be recalculated every time a new page filter is added
    if (widgetDataset) {
      const fields = this.setWidgetFields(widgetDataset)[1];
      if (fields.length > 0) {
        const scope = this.onFieldSelectionChanged(
          widget,
          fields,
          pageFilters
        )[1];

        if (scope) {
          widget.scope = scope;
        }
      }
    }
  }
}
