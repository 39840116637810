import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {ColorService} from '../../helpers/color.service';
import {ReportTheme} from '../../models/report/report-theme.model';
import {NgClass, NgStyle} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-theme-preview',
  templateUrl: './theme-preview.component.html',
  styleUrls: ['./theme-preview.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle, TranslateModule],
})
export class ThemePreviewComponent implements OnInit, OnChanges {
  @Input() theme: ReportTheme;
  @Input() reportTheme?: ReportTheme;
  @Input() imgSrc;
  @Input() bckImgSrc;

  public bckImgSrcPivot;
  public stripeColor;
  public pageActive: number;

  constructor(private colorService: ColorService) {}

  ngOnInit(): void {
    this.pageActive = 1;
    this.theme = Object.assign(this.theme, {...this.theme});
    let rowColor = this.theme?.pageColor
      ? this.theme.pageColor
      : this.reportTheme?.pageColor;
    if (this.theme.tableRowBgColor) {
      rowColor = this.theme.tableRowBgColor;
    }

    if (rowColor) {
      if (this.colorService.isDark(rowColor as string)) {
        this.stripeColor = this.colorService.pSBC(0.1, rowColor);
      } else {
        this.stripeColor = this.colorService.pSBC(-0.7, rowColor);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imgSrc) {
      this.imgSrc = changes.imgSrc?.currentValue;
    }

    if (changes.bckImgSrc) {
      this.bckImgSrcPivot =
        changes.bckImgSrc?.currentValue?.changingThisBreaksApplicationSecurity;
    }

    if (changes.theme?.currentValue) {
      if (changes.theme?.currentValue.tableRowBgColor) {
        if (
          this.colorService.isDark(changes.theme?.currentValue.tableRowBgColor)
        ) {
          this.stripeColor = this.colorService.pSBC(
            0.2,
            changes.theme?.currentValue.tableRowBgColor
              ? changes.theme?.currentValue.tableRowBgColor
              : this.reportTheme?.tableRowBgColor
          );
        } else {
          this.stripeColor = this.colorService.pSBC(
            -0.7,
            changes.theme?.currentValue.tableRowBgColor
              ? changes.theme?.currentValue.tableRowBgColor
              : this.reportTheme?.tableRowBgColor
          );
        }
      } else {
        const color = changes.theme?.currentValue.widgetBackgroundColor
          ? changes.theme?.currentValue.widgetBackgroundColor
          : this.reportTheme?.backgroundColor
          ? this.reportTheme.backgroundColor
          : this.reportTheme?.widgetBackgroundColor;
        if (color) {
          if (this.colorService.isDark(color)) {
            this.stripeColor = this.colorService.pSBC(0.2, color);
          } else {
            this.stripeColor = this.colorService.pSBC(-0.7, color);
          }
        }
      }
    }
    this.theme.widgetBackgroundColor = this.theme.widgetBackgroundColor
      ? this.theme.widgetBackgroundColor
      : this.reportTheme?.widgetBackgroundColor ??
        this.reportTheme?.backgroundColor;
  }

  handlePageActive(page: number): void {
    this.pageActive = page;
  }
}
