import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {AuthenticationService} from 'src/app/core/services';
import {canUser, FontService, HelperService} from '../../helpers';
import {ReportTheme, ResFile} from '../../models';
import {PlanType} from '../../enums';
import {NgClass} from '@angular/common';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {UploadInputComponent} from '../upload-input/upload-input.component';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {ColorPickerModule} from 'ngx-color-picker';
import {CtaContainerComponent} from '../cta-container/cta-container.component';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {ThemePreviewComponent} from '../theme-preview/theme-preview.component';

@Component({
  selector: 'app-theme-builder',
  templateUrl: './theme-builder.component.html',
  styleUrls: ['./theme-builder.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatSlideToggle,
    FormsModule,
    ReactiveFormsModule,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    UploadInputComponent,
    MatSelect,
    MatOption,
    ColorPickerModule,
    CtaContainerComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    ThemePreviewComponent,
    TranslateModule,
  ],
})
export class ThemeBuilderComponent implements OnInit, AfterViewInit, OnChanges {
  // Inputs / Outputs
  @Input() fromParent: 'modal' | 'static' | 'modal-page';
  @Input() themeToEdit?: ReportTheme;
  @Input() themeToPreview?: ReportTheme;
  @Input() canPopulateForm? = true;
  @Input() isSavingData?: boolean;
  @Input() parentLogoFile?: File;
  @Input() parentBackgroundFile?: File | null;
  @Input() backButton?: boolean;
  @Input() noDispatchInputFile?: boolean; // if is not required dispatch the resFile inputUpload output.
  @Input() isFromReportDialog?: boolean;
  @Output() sendTheme = new EventEmitter<ReportTheme | null>();
  @Output() goBack = new EventEmitter<boolean>();
  @Output() closeDialog = new EventEmitter<boolean>();

  public themeForm: FormGroup;
  public colorsPalette: FormArray;

  public logoUploadInput: string;
  public backgroundUploadInput: string;
  public placeholderUploadInput: string;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  // images
  public logoSource: string | SafeUrl | null;
  public logoFile: File | null;
  public backgroundSource: string | SafeUrl | null;
  public fontSizes: any;

  // is global
  public canEditForm: boolean;
  public isReadOnly: boolean;
  public isSuperOrOrgAdmin: boolean;

  // Permissions
  userPlan: PlanType = this.helperService.getUserPlan();
  canSaveTheme = canUser('saveTheme', this.userPlan);

  constructor(
    private translate: TranslateService,
    private domSanitizationService: DomSanitizer,
    private router: Router,
    private fontService: FontService,
    public helperService: HelperService,
    private authService: AuthenticationService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.accordion.openAll();
      if (this.fromParent === 'modal') {
        this.themeForm.controls.name.disable();
        this.themeForm.controls.logo.disable();
      }
    }, 0);
  }

  ngOnInit(): void {
    this.fontSizes = this.fontService.getFontSizes;
    this.isSuperOrOrgAdmin =
      this.authService.getCurrentUser().isSuperAdmin ||
      this.authService.checkRole(['ROLE_ORG_ADMIN']);
    this.getInputUploadText();
    this.buildThemeForm();

    if (this.themeToEdit && this.canPopulateForm) {
      this.populateForm();
    }

    if (this.fromParent === 'modal-page' && !this.canPopulateForm) {
      this.populateFormWithoutTheme();
    }
    this.validateRequired();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (
        this.fromParent === 'modal-page' &&
        !this.canPopulateForm &&
        this.themeForm
      ) {
        this.populateFormWithoutTheme();
      }
    }
  }

  onHandleCanEdit(): boolean {
    let canEdit = true;
    if (this.themeToEdit?.isGlobal || this.themeToEdit?.isReadOnly) {
      canEdit = false;
    }

    if (!this.themeToEdit?.isGlobal && this.themeToEdit?.isReadOnly) {
      canEdit = false;
    }

    if (
      this.themeToEdit?.isGlobal === false &&
      this.themeToEdit?.isReadOnly === false
    ) {
      canEdit = true;
    }

    return canEdit;
  }
  buildThemeForm(): void {
    this.themeForm = new FormGroup(
      {
        name: new FormControl(''),
        titleColor: new FormControl(''),
        brandingText: new FormControl('', [Validators.maxLength(49)]),
        pageColor: new FormControl(''),
        widgetBackgroundColor: new FormControl(''),
        bodyColor: new FormControl(''),
        borderColor: new FormControl(''),
        colorPalette: new FormArray([]),
        fontSize: new FormControl(this.fontSizes[1]),
        tableHeaderBgColor: new FormControl(''),
        tableHeaderTextColor: new FormControl(''),
        tableHeaderBorderColor: new FormControl(''),
        tableRowBgColor: new FormControl(''),
        tableRowBorderColor: new FormControl(''),
        stripedRows: new FormControl(false),
        schemaIndicator: new FormControl('opt-1'),
        backgroundImage: new FormControl(''),
        backgroundImageFile: new FormControl(''),
        logo: new FormControl(''),
        logoFile: new FormControl(''),
        isReadOnly: new FormControl(''),
      },
      this.validateColorPalette()
    );
    this.colorsPalette = this.themeForm.get('colorPalette') as FormArray;
  }

  populateForm(): void {
    const widgetBackgroundColorPivot =
      this.themeToEdit?.widgetBackgroundColor ||
      this.themeToEdit?.backgroundColor;
    this.themeForm.patchValue({
      name: this.themeToEdit?.name,
      brandingText: this.themeToEdit?.brandingText,
      titleColor: this.themeToEdit?.titleColor,
      pageColor: this.themeToEdit?.pageColor,
      widgetBackgroundColor: widgetBackgroundColorPivot || null, // (this.themeToEdit?.widgetBackgroundColor || this.themeToEdit?.backgroundColor) ? (this.themeToEdit?.widgetBackgroundColor || this.themeToEdit?.backgroundColor) : null,
      bodyColor: this.themeToEdit?.bodyColor,
      borderColor: this.themeToEdit?.borderColor,
      fontSize: this.themeToEdit?.fontSize
        ? this.fontSizes.filter((f) => f.size === this.themeToEdit?.fontSize)[0]
        : this.fontSizes[1],
      tableHeaderBgColor: this.themeToEdit?.tableHeaderBgColor,
      tableHeaderTextColor: this.themeToEdit?.tableHeaderTextColor,
      tableHeaderBorderColor: this.themeToEdit?.tableHeaderBorderColor,
      tableRowBgColor: this.themeToEdit?.tableRowBgColor,
      tableRowBorderColor: this.themeToEdit?.tableRowBorderColor,
      stripedRows: this.themeToEdit?.stripedRows,
      schemaIndicator: this.themeToEdit?.schemaIndicator || 'opt-1',
      isReadOnly: this.themeToEdit?.isGlobal,
    });
    this.isReadOnly = Boolean(this.themeToEdit?.isReadOnly);
    this.canEditForm = this.onHandleCanEdit();
    const palette = this.themeForm.get('colorPalette') as FormArray;
    if (this.themeToEdit?.colorPalette) {
      this.themeToEdit?.colorPalette.forEach((item) => {
        const color = new FormControl(item, Validators.required);
        palette.push(color);
      });
    }

    this.disableInputs();
  }

  disableInputs(): void {
    if (!this.canEditForm && !this.isSuperOrOrgAdmin) {
      this.themeForm.disable();
    }
  }

  // only for page settings
  populateFormWithoutTheme(): void {
    this.themeForm.patchValue({
      name: this.themeToEdit?.name,
      fontSize: this.themeToEdit?.fontSize
        ? this.fontSizes.filter((f) => f.size === this.themeToEdit?.fontSize)[0]
        : this.fontSizes[1],
    });
    if (this.noDispatchInputFile && this.parentBackgroundFile) {
      const pivotParentFile = this.parentBackgroundFile;
      const reader = new FileReader();
      reader.readAsDataURL(pivotParentFile);
      reader.onload = (): void => {
        const imgSrc = this.domSanitizationService.bypassSecurityTrustUrl(
          reader.result?.toString() as string
        );
        this.backgroundSource = imgSrc;
      };
      this.parentBackgroundFile = null;
    }
  }

  // Dynamic color fields for colorPalette
  addColor(): void {
    const field = this.createColor();
    this.colorsPalette.push(field);
  }

  createColor(): FormControl {
    const color = new FormControl('', Validators.required);
    return color;
  }

  removeColor(index, event): void {
    this.colorsPalette.removeAt(index);
  }

  onPreventEnterKey(event): void {
    event.keyCode === 13 && event.preventDefault();
  }

  validateColorPalette(): ValidatorFn {
    return (formGroup: AbstractControl): {[key: string]: any} | null => {
      const colors = formGroup.get('colorPalette') as FormArray;
      if (colors.value.length < 1 && this.fromParent !== 'modal-page') {
        colors.setErrors({invalidColors: true});
        return {invalidColors: true};
      } else {
        colors.setErrors(null);
        return null;
      }
    };
  }

  validateRequired(): void {
    let fieldToValidate = [
      'name',
      'titleColor',
      'pageColor',
      'widgetBackgroundColor',
      'bodyColor',
      'borderColor',
      'colorPalette',
    ];

    if (!this.themeForm) {
      return;
    }

    if (this.fromParent === 'modal-page') {
      fieldToValidate = ['name'];
    }

    Object.keys(this.themeForm.controls).forEach((control) => {
      const fControl = fieldToValidate.find((el) => el === control);
      if (fControl) {
        this.themeForm.controls[fControl].addValidators(Validators.required);
      }
    });

    this.themeForm.updateValueAndValidity();
  }

  getInputUploadText(): void {
    this.translate
      .get('theme.form.logo')
      .subscribe((res) => (this.logoUploadInput = res));
    this.translate
      .get('theme.form.background_image')
      .subscribe((res) => (this.backgroundUploadInput = res));
    this.translate
      .get('theme.form.placeholder.logo')
      .subscribe((res) => (this.placeholderUploadInput = res));
  }

  setLogo(event: ResFile): void {
    this.logoSource = event ? event.fileSource : null;
    this.themeForm.controls.logo.setValue(event ? event.fileName : '');
    this.themeForm.controls.logoFile.setValue(event ? event.fileFile : '');
  }

  setBackgroundImage(event: ResFile): void {
    const src =
      typeof event?.fileSource !== 'string'
        ? event?.fileSource
        : this.domSanitizationService.bypassSecurityTrustUrl(event?.fileSource);
    this.backgroundSource = event ? src : null;
    this.themeForm.controls.backgroundImage.setValue(
      event ? event.fileName : ''
    );
    this.themeForm.controls.backgroundImageFile.setValue(
      event ? event.fileFile : ''
    );
  }

  onApply(isACopy?: boolean): void {
    this.themeForm.get('isReadOnly')?.setValue(this.isReadOnly);

    const payloadTheme = Object.keys(this.themeForm.controls).reduce(
      (payload, value) => {
        payload = Object.assign(payload, {
          [value]: this.themeForm.controls[value].value,
        });

        return payload;
      },
      {isACopy: isACopy}
    );

    this.sendTheme.emit(payloadTheme);
  }

  onCancel(): void {
    if (this.fromParent === 'modal' || this.fromParent === 'modal-page') {
      this.sendTheme.emit(null);
    }
    if (this.fromParent === 'static') {
      !this.isFromReportDialog
        ? this.router.navigate(['themes'])
        : this.goBack.emit(true);
    }
  }

  onColorReset(control): void {
    this.themeForm.get(control)?.setValue(null);
  }

  onColorPaletteReset(index): void {
    this.colorsPalette.at(index).setValue(null);
  }

  onGoBack(): void {
    this.goBack.emit(true);
  }

  onCloseDialog(): void {
    this.closeDialog.emit(true);
  }

  onChangeColor(control): void {
    const inputValue = this.themeForm.get(control)?.value;
    this.themeForm.get(control)?.setValue(inputValue);
  }
}
