<form
  (ngSubmit)="onSaveWidget()"
  [formGroup]="widgetDataForm()"
  id="widgetDataForm"
>
  <div class="row mx-0 pt-2">
    <!-- Chart Type -->
    @if (this.widget.widgetType === "chart") {
      <app-chart-type
        [widget]="widget"
        [widgetDataForm]="widgetDataForm()"
        class="px-0"
        (widgetType)="chartTypeChanged($event)"
      />
    }
  </div>

  <div class="row mx-0 pt-2">
    <!-- Dataset -->
    <div class="col-12 col-md-6 px-1 mb-3">
      <div class="d-flex align-items-center mb-2">
        <label class="d-inline-block mb-0" for="dataset">
          {{
            "reporting.widget_editor.data_form.label_dataset" | translate
          }}&nbsp;<span class="required-mark">*</span>
        </label>
        <i
          class="fa-regular fa-circle-info ms-1"
          matTooltip="{{
            'reporting.widget_editor.data_form.dataset_info' | translate
          }}"
          matTooltipPosition="after"
          matTooltipClass="wsm-tooltip p-0"
        >
        </i>
      </div>
      <input
        #dataSetSelect
        [class.border-danger]="
          datasetFilterCtrl?.errors?.required && datasetFilterCtrl.touched
        "
        [formControl]="datasetFilterCtrl"
        [matAutocomplete]="dataSets"
        placeholder="{{
          'reporting.widget_editor.data_form.placeholder_dataset' | translate
        }}"
        class="wsm-input w-100"
        id="dataset"
        type="text"
      />
      <mat-autocomplete
        #dataSets="matAutocomplete"
        [displayWith]="displayDataset"
        (optionSelected)="confirmationDatasetDialog($event)"
        class="custom-widget-autocomplete"
      >
        @for (dataset of filteredDataSets | async; track dataset) {
          <mat-option [value]="dataset">
            <span class="d-block mb-1">{{ dataset.name }}</span>
            <app-connection-logos
              [connections]="dataset?.connections!"
              [type]="'widget'"
            ></app-connection-logos>
          </mat-option>
        }
      </mat-autocomplete>
      @if (datasetFilterCtrl.errors?.required && datasetFilterCtrl.touched) {
        <div class="wsm-text-danger wsm-caption">
          {{ "reporting.widget_editor.data_form.label_required" | translate }}
        </div>
      }
      @if (
        datasetFilterCtrl.errors?.invalidDataset &&
        datasetFilterCtrl.touched &&
        datasetFilterCtrl.value
      ) {
        <div class="wsm-text-danger wsm-caption">
          {{
            "reporting.widget_editor.data_form.label_invalid_dataset"
              | translate
          }}
        </div>
      }
    </div>
  </div>

  <div class="row mx-0 pt-2">
    <!-- Dimensions -->
    @if (
      widget?.widgetType !== "scorecard" &&
      widget?.chartType !== "funnel" &&
      widget.widgetType !== "dynamictext" &&
      widget?.widgetType !== "gallery" &&
      widget?.widgetType !== "gauge" &&
      widget?.widgetType !== "current-goal"
    ) {
      <div class="col-12 col-md-6 px-1 mb-3">
        <div class="mb-2">
          <div class="d-flex align-items-center">
            <label class="d-inline-block mb-0">
              {{
                "reporting.widget_editor.data_form.label_dimension" | translate
              }}&nbsp;<span class="required-mark">*</span>
            </label>
            <i
              class="fa-regular fa-circle-info ms-1"
              matTooltip="{{
                (widget.chartType
                  ? widgetDimensionInfoTranslate()[widget.chartType]
                  : widgetDimensionInfoTranslate()[widget.widgetType]
                ) | translate
              }}"
              matTooltipPosition="after"
              matTooltipClass="wsm-tooltip p-0"
            >
            </i>
          </div>
          @if (!datasetFilterCtrl.value) {
            <p class="wsm-caption my-1 wsm-text-grayed">
              {{
                "reporting.widget_editor.data_form.label_dimension_info"
                  | translate
              }}
            </p>
          }
        </div>
        <div cdkDropListGroup>
          <div
            #dimensionsList
            (cdkDropListDropped)="onDropMeasure($event, 'dimension')"
            aria-label="Widget Fields"
            cdkDropList
            cdkDropListOrientation="vertical"
          >
            @if (allowMoreDimensions) {
              <mat-chip-option
                #dimensionTrigger="matMenuTrigger"
                (click)="updateDimensionForm(null)"
                [disabled]="
                  !datasetFilterCtrl.value || datasetFilterCtrl.invalid
                "
                [matMenuTriggerData]="{index: -1}"
                [matMenuTriggerFor]="newDimensionMenu"
                [ngClass]="{
                  'border border-danger':
                    widget.dimensions?.length! === 0 &&
                    newDimensionForm.touched,
                }"
                class="d-inline-flex"
              >
                <i class="fa-regular fa-plus fa-xs me-1"></i>
                {{
                  "reporting.widget_editor.data_form.label_add_dimension"
                    | translate
                }}
              </mat-chip-option>
            }
            @for (field of widget.dimensions; track field.id; let i = $index) {
              <div class="my-2">
                <mat-chip-option
                  (click)="updateDimensionForm(field)"
                  (removed)="removeMeasure(field, true)"
                  [matMenuTriggerData]="{index: i}"
                  [matMenuTriggerFor]="newDimensionMenu"
                  [removable]="true"
                  cdkDrag
                  class="wsm-mat-chip d-inline-flex move-chip"
                >
                  <i class="fa-regular fa-grip-dots-vertical"></i>
                  <span class="me-1 pe-1">{{ field.aggregation }} </span>
                  <span
                    matTooltip="{{ field.name }}"
                    matTooltipClass="wsm-tooltip"
                  >
                    {{ field.name | truncate: 15 }}&nbsp;
                  </span>
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip-option>
              </div>
            }
          </div>
          <mat-menu
            #newDimensionMenu="matMenu"
            (closed)="dimensionMenuClose()"
            xPosition="after"
            yPosition="above"
          >
            <ng-template let-index="index" matMenuContent>
              <div (click)="$event.stopPropagation()" class="row mx-0 p-2">
                <div class="col-12 mb-3">
                  <label>
                    {{
                      "reporting.widget_editor.data_form.label_choose_field"
                        | translate
                    }}&nbsp;
                    <span class="required-mark">*</span>
                  </label>
                  <input
                    #dimensionIdSelect
                    [formControl]="dimensionFilterCtrl"
                    [matAutocomplete]="dimensions"
                    class="wsm-input w-100"
                    id="dimension"
                    placeholder="{{
                      'reporting.widget_editor.data_form.label_select_field'
                        | translate
                    }}"
                    type="text"
                  />
                  <mat-autocomplete
                    #dimensions="matAutocomplete"
                    [displayWith]="displayDimension"
                    autoActiveFirstOption
                    class="custom-widget-autocomplete"
                  >
                    @for (field of filteredDimensions | async; track field) {
                      <mat-option
                        [value]="field"
                        [disabled]="field.isDisabled || !field.isSupported"
                        [matTooltip]="
                          field.incompatibleWithFieldsTooltip ||
                          (!field.isSupported
                            ? fieldNoSupportedTranslate()
                            : '')
                        "
                        matTooltipClass="wsm-tooltip tooltip-right"
                        matTooltipPosition="right"
                        [title]="field.name"
                      >
                        <span class="d-block mb-1">{{ field.name }}</span>
                        <app-connection-logos
                          [connections]="field?.connections!"
                          [type]="'widget'"
                        >
                        </app-connection-logos>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </div>
                <!--dimensionFormTemplate-->
                <ng-container
                  *ngTemplateOutlet="
                    dimensionFormTemplate;
                    context: {$implicit: index}
                  "
                ></ng-container>
              </div>
            </ng-template>
          </mat-menu>
        </div>
      </div>
    }

    <!-- Metrics -->
    @if (
      widget.widgetType !== "dynamictext" && widget.widgetType !== "scorecard"
    ) {
      <div class="col-12 col-md-6 px-1 mb-3">
        <div class="mb-2">
          <div class="d-flex align-items-center">
            <label class="d-inline-block mb-0">
              {{
                "reporting.widget_editor.data_form.label_metrics" | translate
              }}&nbsp;<span class="required-mark">*</span>
            </label>
            <i
              class="fa-regular fa-circle-info ms-1"
              matTooltip="{{
                (widget.chartType
                  ? widgetMetricInfoTranslate()[widget.chartType]
                  : widgetMetricInfoTranslate()[widget.widgetType]
                ) | translate
              }}"
              matTooltipPosition="after"
              matTooltipClass="wsm-tooltip p-0"
            >
            </i>
          </div>
          @if (!datasetFilterCtrl.value) {
            <p class="wsm-caption my-1 wsm-text-grayed">
              {{
                "reporting.widget_editor.data_form.label_dimension_info"
                  | translate
              }}
            </p>
          }
        </div>
        <div cdkDropListGroup>
          <div
            #measuresList
            (cdkDropListDropped)="onDropMeasure($event, 'metric')"
            aria-label="Widget Metrics"
            cdkDropList
            cdkDropListOrientation="vertical"
          >
            @if (allowMoreFields) {
              <mat-chip-option
                #metricTrigger="matMenuTrigger"
                (click)="updateMetricForm(null)"
                [disabled]="
                  !datasetFilterCtrl.value || datasetFilterCtrl.invalid
                "
                [matMenuTriggerData]="{index: -1}"
                [matMenuTriggerFor]="newMeasureMenu"
                [ngClass]="{
                  'border border-danger':
                    widget.metrics?.length! === 0 && newMeasureForm.touched,
                }"
                class="d-inline-flex"
              >
                <i class="fa-regular fa-plus fa-xs me-1"></i>
                {{
                  "reporting.widget_editor.data_form.label_add_metric"
                    | translate
                }}
              </mat-chip-option>
            }
            @for (field of widget.metrics; track field.id; let i = $index) {
              <div class="my-2">
                <mat-chip-option
                  (click)="updateMetricForm(field)"
                  (removed)="removeMeasure(field, false)"
                  [matMenuTriggerData]="{index: i}"
                  [matMenuTriggerFor]="newMeasureMenu"
                  [removable]="true"
                  cdkDrag
                  class="wsm-mat-chip d-inline-flex move-chip"
                >
                  <i class="fa-regular fa-grip-dots-vertical me-2"></i>
                  <span class="me-1 pe-1 widget-filed-aggregation"
                    >{{ field.aggregation }}
                  </span>
                  <span
                    matTooltip="{{ field.name }}"
                    matTooltipClass="wsm-tooltip"
                  >
                    {{ field.name | truncate: 15 }}&nbsp;
                  </span>
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip-option>
              </div>
            }
          </div>
          <mat-menu
            #newMeasureMenu="matMenu"
            xPosition="after"
            yPosition="above"
          >
            <ng-template let-index="index" matMenuContent>
              <div (click)="$event.stopPropagation()" class="row mx-0 p-2">
                <div class="col-12 mb-3">
                  <label for="measure">
                    {{
                      "reporting.widget_editor.data_form.label_choose_field"
                        | translate
                    }}&nbsp;
                    <span class="required-mark">*</span>
                  </label>
                  <input
                    #fieldSelect
                    [formControl]="metricFilterCtrl"
                    [matAutocomplete]="metrics"
                    class="wsm-input w-100"
                    id="measure"
                    placeholder="{{
                      'reporting.widget_editor.data_form.label_select_field'
                        | translate
                    }}"
                    type="text"
                  />
                  <mat-autocomplete
                    #metrics="matAutocomplete"
                    [displayWith]="displayMetric"
                    autoActiveFirstOption
                    class="custom-widget-autocomplete"
                  >
                    @for (field of filteredMetrics | async; track field) {
                      <mat-option
                        [value]="field"
                        [disabled]="field.isDisabled || !field.isSupported"
                        [matTooltip]="
                          field.incompatibleWithFieldsTooltip ||
                          (!field.isSupported
                            ? fieldNoSupportedTranslate()
                            : '')
                        "
                        matTooltipPosition="right"
                        matTooltipClass="wsm-tooltip tooltip-right"
                        [title]="field.name"
                      >
                        <span class="d-block mb-1">{{ field.name }}</span>
                        <app-connection-logos
                          [connections]="field?.connections!"
                          [isCustom]="field?.isCustom"
                          [type]="'widget'"
                        >
                        </app-connection-logos>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </div>
                <!--metricFormTemplate-->
                <ng-container
                  *ngTemplateOutlet="
                    metricFormTemplate;
                    context: {$implicit: index}
                  "
                >
                </ng-container>
              </div>
            </ng-template>
          </mat-menu>
        </div>
      </div>
    }

    <!--ADR-2948: Row Count Feature-->
    <!--fields-->
    @if (widget.widgetType === "scorecard") {
      <div class="col-lg-12 col-md-6 px-1 mb-3">
        <div class="mb-2">
          <div class="d-flex align-items-center">
            <label class="d-inline-block mb-0">
              {{
                "reporting.widget_editor.data_form.label_fields" | translate
              }}&nbsp;<span class="required-mark">*</span>
            </label>
            <i
              class="fa-regular fa-circle-info ms-1"
              matTooltip="{{
                (widget.chartType
                  ? widgetMetricInfoTranslate()[widget.chartType]
                  : widgetMetricInfoTranslate()[widget.widgetType]
                ) | translate
              }}"
              matTooltipPosition="after"
              matTooltipClass="wsm-tooltip p-0"
            >
            </i>
          </div>
          @if (!datasetFilterCtrl.value) {
            <p class="wsm-caption my-1 wsm-text-grayed">
              {{
                "reporting.widget_editor.data_form.label_dimension_info"
                  | translate
              }}
            </p>
          }
          @if (allowMoreFields) {
            <mat-chip-option
              #scorecardFieldTrigger="matMenuTrigger"
              (click)="updateScorecardField(null)"
              [disabled]="!datasetFilterCtrl.value || datasetFilterCtrl.invalid"
              [matMenuTriggerFor]="newFieldScorecardMenu"
              [ngClass]="{
                'border border-danger':
                  widget.metrics?.length! === 0 && newMeasureForm.touched,
              }"
              class="d-inline-flex mt-2"
            >
              <i class="fa-regular fa-plus fa-xs me-1"></i>
              {{
                "reporting.widget_editor.data_form.label_add_field" | translate
              }}
            </mat-chip-option>
          }
          @if (scorecardField()) {
            <mat-chip-option
              #scorecardFieldTrigger="matMenuTrigger"
              (click)="updateScorecardField(scorecardField())"
              (removed)="removeMeasure(scorecardField()!, isDimensionField())"
              [matMenuTriggerData]="{index: 0}"
              [matMenuTriggerFor]="newFieldScorecardMenu"
              [removable]="true"
              cdkDrag
              class="wsm-mat-chip d-inline-flex move-chip mt-2"
            >
              <span class="me-1 pe-1 widget-filed-aggregation"
                >{{ scorecardField()?.aggregation }}
              </span>
              <span
                matTooltip="{{ scorecardField()?.name }}"
                matTooltipClass="wsm-tooltip"
              >
                {{ scorecardField()?.name || "" | truncate: 15 }}&nbsp;
              </span>
              <mat-icon matChipRemove>close</mat-icon>
            </mat-chip-option>
          }
        </div>
        <mat-menu
          #newFieldScorecardMenu="matMenu"
          xPosition="after"
          yPosition="above"
        >
          <ng-template matMenuContent>
            <div (click)="$event.stopPropagation()" class="row mx-0 p-2">
              <div class="col-12 mb-3">
                <label for="scorecardField">
                  {{
                    "reporting.widget_editor.data_form.label_choose_field"
                      | translate
                  }}&nbsp;
                  <span class="required-mark">*</span>
                </label>
                <input
                  #scorecardFieldSelect
                  [formControl]="scoreCardFieldFilterCtrl"
                  [matAutocomplete]="scorecardFields"
                  class="wsm-input w-100"
                  id="scorecardField"
                  placeholder="{{
                    'reporting.widget_editor.data_form.label_select_field'
                      | translate
                  }}"
                  type="text"
                />
                <mat-autocomplete
                  #scorecardFields="matAutocomplete"
                  [displayWith]="displayScorecardField"
                  autoActiveFirstOption
                  (optionSelected)="onScorecardFieldChanged($event)"
                  class="custom-widget-autocomplete"
                >
                  @for (field of filteredFields(); track field) {
                    <mat-option
                      [value]="field"
                      [disabled]="
                        field.isDisabled ||
                        (datasetNoRowCount()[selectedDataset._id!] &&
                          field.isDimension) ||
                        !field.isSupported
                      "
                      [matTooltip]="
                        field.incompatibleWithFieldsTooltip ||
                        ((datasetNoRowCount()[selectedDataset._id!] &&
                          field.isDimension) ||
                        !field.isSupported
                          ? fieldNoSupportedTranslate()
                          : '')
                      "
                      matTooltipPosition="right"
                      matTooltipClass="wsm-tooltip tooltip-right"
                      [title]="field.name"
                    >
                      <span class="d-block mb-1">{{ field.name }}</span>
                      <app-connection-logos
                        [connections]="field?.connections!"
                        [isCustom]="field?.isCustom"
                        [type]="'widget'"
                      >
                      </app-connection-logos>
                    </mat-option>
                  }
                </mat-autocomplete>
              </div>
              @if (scoreCardFieldFilterCtrl.value?.id) {
                <ng-container
                  *ngTemplateOutlet="
                    isDimensionField()
                      ? dimensionFormTemplate
                      : metricFormTemplate;
                    context: {$implicit: 0}
                  "
                ></ng-container>
              }
            </div>
          </ng-template>
        </mat-menu>
      </div>
    }
    <!--ADR-2948: Row Count Feature-->
    <ng-template #metricFormTemplate let-index>
      <form
        (ngSubmit)="onMeasureChanged(index)"
        [formGroup]="newMeasureForm"
        id="editMeasureForm"
      >
        <div class="col-12 mb-3">
          <label for="measureAggregation">
            {{
              "reporting.widget_editor.data_form.label_aggregation" | translate
            }}&nbsp;
            <span class="required-mark">*</span>
          </label>
          <mat-select
            #measureOperatorSelect
            [panelClass]="'wsm-custom-select'"
            class="wsm-input w-100"
            formControlName="aggregation"
            id="measureAggregation"
            placeholder="{{
              'reporting.widget_editor.data_form.placeholder_aggregation'
                | translate
            }}"
          >
            <mat-option [value]="'sum'">Sum</mat-option>
            <mat-option [value]="'avg'">Average</mat-option>
          </mat-select>
        </div>
        @if (
          metricFilterCtrl.value?.dataType === "currency" ||
          metricFilterCtrl.value?.dataType === "integer" ||
          metricFilterCtrl.value?.dataType === "decimal" ||
          metricFilterCtrl.value?.dataType === "float"
        ) {
          <div class="col-12 mb-3">
            <mat-slide-toggle formControlName="shortenNumbers">
              {{
                "reporting.widget_editor.data_form.label_shorten_numbers"
                  | translate
              }}
            </mat-slide-toggle>
          </div>
        }
        @if (
          widget.chartType === "msline" ||
          widget.chartType === "mscombidy2d" ||
          widget.chartType === "multiaxisline"
        ) {
          <div class="col-12 mb-3">
            <mat-slide-toggle formControlName="useSecondaryAxis">
              {{
                "reporting.widget_editor.data_form.label_secondary_axis"
                  | translate
              }}
            </mat-slide-toggle>
          </div>
        }
        <div class="col-12 d-flex justify-content-end">
          <button
            [disabled]="newMeasureForm.invalid || !metricFilterCtrl.value"
            class="wsm-btn wsm-btn-primary wsm-btn-small"
            form="editMeasureForm"
            type="submit"
          >
            {{
              "reporting.widget_editor.data_form.label_apply_button" | translate
            }}
          </button>
        </div>
      </form>
    </ng-template>
    <ng-template #dimensionFormTemplate let-index>
      <form
        (click)="$event.stopPropagation()"
        (ngSubmit)="onDimensionChanged(index)"
        [formGroup]="newDimensionForm"
        id="editDimensionForm"
      >
        @if (
          dimensionFilterCtrl?.value?.dataType === "adPreview" &&
          newDimensionForm.controls["darkModeEnabled"]
        ) {
          <mat-slide-toggle class="mt-2" formControlName="darkModeEnabled">
            {{
              "reporting.widget_editor.data_form.label_dark_mode" | translate
            }}
          </mat-slide-toggle>
        }
        @if (widget.widgetType === "ranking") {
          <div class="col-12 mb-3">
            <label>
              {{
                "reporting.widget_editor.data_form.label_show_as" | translate
              }}
            </label>
            <mat-button-toggle-group
              class="with-border"
              formControlName="showAs"
              appearance="legacy"
              aria-label="Shapes"
            >
              <mat-button-toggle class="ps-1 my-1" value="phrase">
                {{
                  "reporting.widget_editor.data_form.show_as_phrase" | translate
                }}
              </mat-button-toggle>
              <mat-button-toggle class="pe-1 my-1" value="word">
                {{
                  "reporting.widget_editor.data_form.show_as_word" | translate
                }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        }
        @if (
          dimensionFilterCtrl?.value?.dataType === "date" &&
          newDimensionForm.get("dateConfiguration")
        ) {
          <div class="w-100" formGroupName="dateConfiguration">
            <div class="col-12 mb-3">
              <label for="date-group">
                {{
                  "reporting.widget_editor.data_form.label_group_dates"
                    | translate
                }}&nbsp;
                <span class="required-mark">*</span>
              </label>
              <mat-select
                #dateformatSelect
                [panelClass]="'wsm-custom-select'"
                placeholder="{{
                  'reporting.widget_editor.data_form.placeholder_group_dates'
                    | translate
                }}"
                class="wsm-input w-100"
                formControlName="dateGroup"
                id="date-group"
              >
                <mat-option [value]="'dateHourMinute'"
                  >Date, Hour, Minute</mat-option
                >
                <mat-option [value]="'date'">Date</mat-option>
                <mat-option [value]="'dayHours'">Hours of the Day</mat-option>
                <mat-option [value]="'dayOfWeek'">Day of the Week</mat-option>
                <mat-option [value]="'week'">Week</mat-option>
                <mat-option [value]="'month'">Month</mat-option>
                <mat-option [value]="'monthYear'">Month, Year</mat-option>
                <mat-option [value]="'monthDay'">Month, Day</mat-option>
                <mat-option [value]="'quarter'">Quarter</mat-option>
                <mat-option [value]="'year'">Year</mat-option>
              </mat-select>
            </div>
            <div class="col-12 mb-3">
              <label for="date-format">
                {{
                  "reporting.widget_editor.data_form.label_date_format"
                    | translate
                }}&nbsp;
                <span class="required-mark">*</span>
              </label>
              <mat-select
                #dateformatSelect
                [panelClass]="'wsm-custom-select'"
                placeholder="{{
                  'reporting.widget_editor.data_form.placeholder_date_format'
                    | translate
                }}"
                class="wsm-input w-100"
                formControlName="dateFormat"
                id="date-format"
              >
                @if (
                  newDimensionForm.value?.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  newDimensionForm.value?.dateConfiguration?.dateGroup !==
                    "dayHours" &&
                  newDimensionForm.value?.dateConfiguration?.dateGroup ===
                    "dateHourMinute"
                ) {
                  <mat-option [value]="'dateHourMinute'">
                    Date, Hour, Minute ({{
                      today | date: "yyyy-MM-dd h:mm a z"
                    }})
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayHours" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "week" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "month" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "monthYear" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "monthDay" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "quarter" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !== "year"
                ) {
                  <mat-option [value]="'date'">
                    Date ({{ today | date: "yyyy-MM-dd" }})
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  (newDimensionForm.value.dateConfiguration?.dateGroup ===
                    "dateHourMinute" ||
                    newDimensionForm.value.dateConfiguration?.dateGroup ===
                      "dayHours")
                ) {
                  <mat-option [value]="'dayHourStandard'">
                    Hour of Day (12:00 AM - 12:00 PM)
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  (newDimensionForm.value.dateConfiguration?.dateGroup ===
                    "dateHourMinute" ||
                    newDimensionForm.value.dateConfiguration?.dateGroup ===
                      "dayHours")
                ) {
                  <mat-option [value]="'dayHours'">
                    Hour of Day (00:00 - 23:00)
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup ===
                    "dayOfWeek" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayHours"
                ) {
                  <mat-option [value]="'shortDay'">
                    Day of the Week ({{ today | date: "EE" }})
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup ===
                    "dayOfWeek" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayHours"
                ) {
                  <mat-option [value]="'longDay'">
                    Day of the Week ({{ today | date: "EEEE" }})
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayHours" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "week" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "monthYear" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "monthDay" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "quarter" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !== "year"
                ) {
                  <mat-option [value]="'monthDayYear'">
                    Month, Day, Year ({{ today | date: "MMM dd, yyyy" }})
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup ===
                    "date" ||
                  newDimensionForm.value.dateConfiguration?.dateGroup ===
                    "dateHourMinute" ||
                  newDimensionForm.value.dateConfiguration?.dateGroup === "week"
                ) {
                  <mat-option [value]="'week'">
                    Week ({{ today | date: "MMM dd - yy" }})
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "week" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayHours" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "quarter" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !== "year"
                ) {
                  <mat-option [value]="'month'">
                    Month ({{ today | date: "MMM" }})
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "week" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayHours" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "monthDay" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "quarter" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !== "year"
                ) {
                  <mat-option [value]="'monthYear'">
                    Month, Year ({{ today | date: "MMM - yy" }})
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "week" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayHours" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "monthYear" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "quarter" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !== "year"
                ) {
                  <mat-option [value]="'monthDay'">
                    Month, Day ({{ today | date: "MMM, dd" }})
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayHours" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "monthDay" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !== "year"
                ) {
                  <mat-option [value]="'quarter'">
                    Quarter ( {{ today | date: "YY" }} - Q1)
                  </mat-option>
                }
                @if (
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayOfWeek" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "dayHours" &&
                  newDimensionForm.value.dateConfiguration?.dateGroup !==
                    "monthDay"
                ) {
                  <mat-option [value]="'year'">
                    Year ({{ today | date: "yyyy" }})
                  </mat-option>
                }
              </mat-select>
            </div>
          </div>
        }
        @if (widget.widgetType === "scorecard") {
          <div class="col-12 mb-3">
            <label for="measureAggregation">
              {{
                "reporting.widget_editor.data_form.label_aggregation"
                  | translate
              }}&nbsp;
              <span class="required-mark">*</span>
            </label>
            <mat-select
              [panelClass]="'wsm-custom-select'"
              class="wsm-input w-100"
              placeholder="{{
                'reporting.widget_editor.data_form.placeholder_aggregation'
                  | translate
              }}"
              [value]="'count'"
            >
              <mat-option [value]="'count'">Count</mat-option>
            </mat-select>
          </div>
        }
        <div class="col-12 d-flex justify-content-end">
          <button
            [disabled]="!newDimensionForm.valid || !dimensionFilterCtrl.value"
            class="wsm-btn wsm-btn-primary wsm-btn-small"
            form="editDimensionForm"
            type="submit"
          >
            {{
              "reporting.widget_editor.data_form.label_apply_button" | translate
            }}
          </button>
        </div>
      </form>
    </ng-template>
    <!-- Goal Amount -->
    @if (
      widget?.widgetType === "gauge" ||
      widget?.widgetType === "current-goal" ||
      widget?.widgetType === "timeline"
    ) {
      <div class="row px-1 mb-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="d-flex align-items-center mb-2">
            <label class="d-inline-block mb-0" for="goalAmount"
              >{{
                "reporting.widget_editor.data_form.goal_amount_label"
                  | translate
              }}
              <span class="required-mark">*</span>
            </label>
            <i
              class="fa-regular fa-circle-info ms-1"
              matTooltip="{{
                (widget.chartType
                  ? widgetGoalAmountTranslate()[widget.chartType]
                  : widgetGoalAmountTranslate()[widget.widgetType]
                ) | translate
              }}"
              matTooltipPosition="after"
              matTooltipClass="wsm-tooltip p-0"
            >
            </i>
          </div>
          <input
            formControlName="goalAmount"
            type="number"
            class="wsm-input w-100"
            id="goalAmount"
            placeholder="{{
              'reporting.widget_editor.data_form.goal_amount_placeholder'
                | translate
            }}"
          />
        </div>
      </div>
    }

    <!-- Filters -->
    <div class="col-12 px-1 mb-3">
      <div class="mb-2">
        <div class="d-flex align-items-center">
          <label class="d-inline-block mb-0" for="filters">
            {{ "reporting.widget_editor.data_form.filter_label" | translate }}
          </label>
          <i
            class="fa-regular fa-circle-info ms-1"
            matTooltip="{{
              'reporting.widget_editor.data_form.filter_info' | translate
            }}"
            matTooltipPosition="after"
            matTooltipClass="wsm-tooltip p-0"
          >
          </i>
        </div>
        @if (!datasetFilterCtrl.value) {
          <p class="wsm-caption my-1 wsm-text-grayed">
            {{
              "reporting.widget_editor.data_form.label_dimension_info"
                | translate
            }}
          </p>
        }
      </div>

      <mat-chip-listbox
        [disabled]="
          !fields || !datasetFilterCtrl.value || datasetFilterCtrl.invalid
        "
        aria-label="Page Filters"
      >
        <mat-chip-option
          [matMenuTriggerFor]="btnFilterMenu"
          id="filters"
          class="d-inline-flex"
        >
          <i class="fa-regular fa-filter me-1"></i>
          {{ "reporting.widget_editor.data_form.add_filter_label" | translate }}
        </mat-chip-option>

        <mat-menu #btnFilterMenu="matMenu">
          <ng-template matMenuContent>
            <button
              [matMenuTriggerData]="{index: -1}"
              [matMenuTriggerFor]="filterMenu"
              mat-menu-item
            >
              {{
                "reporting.widget_editor.data_form.basic_filter_label"
                  | translate
              }}
            </button>
            <button (click)="addFilterLogic()" mat-menu-item>
              {{
                "reporting.widget_editor.data_form.edit_filter_logic_label"
                  | translate
              }}
            </button>
          </ng-template>
        </mat-menu>

        @for (filter of widget.filters; track i; let i = $index) {
          <!-- "or" filters -->
          @if (filter?.expressions) {
            <div
              class="mt-2 ms-1"
              [ngClass]="{'or-container': filter?.expressions?.length! > 0}"
            >
              @for (fl of filter.expressions; track fl; let j = $index) {
                <mat-chip-option
                  (removed)="removeFilter(filter, i, fl)"
                  [matMenuTriggerData]="{
                    index: i,
                    subIndex: j,
                    currentFilter: fl,
                  }"
                  [matMenuTriggerFor]="filterMenu"
                  class="wsm-mat-chip d-inline-flex"
                  [removable]="true"
                >
                  <span>{{ fl?.fieldName }}</span>
                  <app-filter-operator [filter]="fl" />
                  @if (fl.value) {
                    <span>{{ fl.value | joinStringArray }}</span>
                  }
                  @if (fl.labels) {
                    <span>{{ fl.labels | joinStringArray }}</span>
                  }
                  <mat-icon matChipRemove>close</mat-icon>
                </mat-chip-option>
                @if (j < filter.expressions?.length! - 1) {
                  <span class="badge mx-1">OR</span>
                }
              }
            </div>
            @if (
              i < widget.filters?.length! - 1 &&
              filter?.expressions?.length! > 0
            ) {
              <div class="mt-2 ms-1">
                <span class="badge mx-1">AND</span>
              </div>
            }
          } @else {
            <div class="ms-1">
              <mat-chip-option
                (removed)="removeFilter(filter, i)"
                [matMenuTriggerData]="{index: i, currentFilter: filter}"
                [matMenuTriggerFor]="filterMenu"
                class="wsm-mat-chip d-inline-flex"
                [removable]="true"
              >
                <span>{{ filter?.fieldName }}</span>
                <app-filter-operator [filter]="filter" />
                @if (filter.value) {
                  <span>{{ filter.value | joinStringArray }}</span>
                }
                @if (filter.labels) {
                  <span>{{ filter.labels | joinStringArray }}</span>
                }
                <mat-icon matChipRemove>close</mat-icon>
              </mat-chip-option>
              @if (i < widget.filters?.length! - 1) {
                <span class="badge mx-1">AND</span>
              }
            </div>
          }
          <!-- "and" filters -->
        }

        <mat-menu
          #filterMenu="matMenu"
          class="menu-container"
          yPosition="above"
        >
          <ng-template
            let-currentFilter="currentFilter"
            let-index="index"
            let-subIndex="subIndex"
            matMenuContent
          >
            <app-filter-selection
              [availableFilters]="fields"
              [currentFilter]="currentFilter"
              (filterCompleted)="onFilterApplied($event, index, subIndex)"
              [connections]="pageConnections"
            />
          </ng-template>
        </mat-menu>
      </mat-chip-listbox>
    </div>

    <!-- Sort By -->
    @if (widget | showSortBy) {
      <div class="col-12 px-1 mb-3">
        <label for="sortField">
          {{
            "reporting.widget_editor.data_form.sort_by_label" | translate
          }}&nbsp;
          <span class="required-mark">*</span>
        </label>
        <div class="sort-by-group">
          <mat-select
            [panelClass]="'wsm-custom-select'"
            class="wsm-input flex-grow-1"
            formControlName="sortField"
            id="sortField"
            placeholder="{{
              'reporting.widget_editor.data_form.sort_by_placeholder'
                | translate
            }}"
          >
            @if (widget.widgetType !== "biggestchanges") {
              @for (field of sortFields; track field) {
                @if (field.aggregation) {
                  <mat-option value="{{ field.id }}_{{ field.aggregation }}">
                    {{ field.name }}
                  </mat-option>
                } @else {
                  <mat-option value="{{ field.id }}">{{
                    field.name
                  }}</mat-option>
                }
              }
            }
            @if (widget.widgetType === "biggestchanges") {
              <mat-option [value]="'delta'">Delta</mat-option>
              <mat-option [value]="'deltaPercent'">Delta Percent</mat-option>
            }
          </mat-select>
          <button
            (click)="toggleSortOrder()"
            class="wsm-btn wsm-btn-primary custom-btn"
            type="button"
          >
            <i
              class="fa-regular"
              [ngClass]="
                widgetDataForm().get('sortDirection')?.value === 'asc'
                  ? 'fa-arrow-up-long'
                  : 'fa-arrow-down-long'
              "
            >
            </i>
          </button>
        </div>
      </div>
    }

    @if (
      widget.widgetType === "scorecard" ||
      widget.widgetType === "table" ||
      widget.widgetType === "ranking" ||
      widget.widgetType === "chart" ||
      widget.widgetType === "gallery" ||
      widget.widgetType === "biggestchanges" ||
      widget.widgetType === "geoHeatMap"
    ) {
      <!-- Result Limit -->
      <div class="col-3 px-1 mb-3">
        <label for="resultLimit">
          {{
            "reporting.widget_editor.data_form.label_result_limit" | translate
          }}
        </label>
        <input
          autocomplete="off"
          [class.border-danger]="
            widgetDataForm().get('resultLimit')?.errors?.pattern &&
            widgetDataForm().get('resultLimit')?.touched
          "
          class="wsm-input w-100"
          formControlName="resultLimit"
          id="resultLimit"
          min="1"
          name="resultLimit"
          placeholder="{{
            'reporting.widget_editor.data_form.result_limit_placeholder'
              | translate
          }}"
          type="number"
        />
        @if (
          widgetDataForm().get("resultLimit")?.errors?.pattern &&
          widgetDataForm().get("resultLimit")?.touched
        ) {
          <div class="wsm-text-danger wsm-caption">
            {{
              "reporting.widget_editor.data_form.label_invalid_result_limit"
                | translate
            }}
          </div>
        }
      </div>
      <!-- Table Total Position -->
      @if (widget.widgetType === "table") {
        <div class="col-3 px-1 mb-3">
          <label for="table-total">
            {{
              "reporting.widget_editor.data_form.label_total_position"
                | translate
            }}
          </label>
          <mat-select
            [panelClass]="'wsm-custom-select'"
            placeholder="{{
              'reporting.widget_editor.data_form.placeholder_total_position'
                | translate
            }}"
            class="wsm-input w-100"
            formControlName="totalPosition"
            id="table-total"
          >
            <mat-option [value]="'bottom'">{{
              "reporting.widget_editor.data_form.bottom_select" | translate
            }}</mat-option>
            <mat-option [value]="'top'">{{
              "reporting.widget_editor.data_form.top_select" | translate
            }}</mat-option>
            <mat-option [value]="'hide'">{{
              "reporting.widget_editor.data_form.hide_select" | translate
            }}</mat-option>
          </mat-select>
        </div>
      }
      <!-- Hide Paginator -->
      @if (widget.widgetType === "table" || widget.widgetType === "ranking") {
        <div class="col-6 px-1 mb-3 d-flex align-items-center">
          <mat-slide-toggle formControlName="hidePaginator" class="mt-4">
            {{
              "reporting.widget_editor.data_form.label_hide_paginator"
                | translate
            }}
          </mat-slide-toggle>
        </div>
      }
    }
  </div>

  <!-- Date Range -->
  <div class="col-12 px-1 mb-3">
    <app-date-picker
      [dateRange]="widget?.dateRange!"
      [origin]="'widget'"
      [isDisabled]="loading"
      (rangeSelection)="onDateSelection($event)"
    >
    </app-date-picker>
  </div>

  <!-- Rich Text Controls -->
  @if (widget.widgetType === "text" || widget.widgetType === "dynamictext") {
    <div class="row mx-0 pt-3">
      <!-- Editor Type -->
      <div
        class="col-12 col-md-6 col-xl-4 px-1 mb-3 w-100 d-flex align-items-center justify-content-between"
      >
        <span class="rich-editor-label">
          {{ "reporting.widget_editor.style_form.rich_text_label" | translate }}
        </span>
        @if (widget.widgetType === "dynamictext") {
          <div class="d-inline-block">
            <mat-button-toggle-group
              [formControl]="ckEditorCtrl"
              appearance="legacy"
              aria-label="Dynamic Text Editor"
            >
              <mat-button-toggle class="ps-1 my-1" [value]="'basic'">
                {{
                  "reporting.widget_editor.style_form.rich_text_basic"
                    | translate
                }}
              </mat-button-toggle>
              <mat-button-toggle class="pe-1 my-1" [value]="'advanced'">
                {{
                  "reporting.widget_editor.style_form.rich_text_advanced"
                    | translate
                }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        }
      </div>
      <div class="col-12 px-1 mb-3">
        @if (ckEditorCtrl?.value === "basic" || widget.widgetType === "text") {
          <div class="widget-editor-ckeditor">
            <ckeditor
              #ckeditor
              [editor]="editor"
              (ready)="onCKEditorReady($event)"
              [config]="editorConfig"
              formControlName="richText"
            >
            </ckeditor>
          </div>
        }
        @if (ckEditorCtrl?.value === "advanced") {
          <app-conditional-metric
            #conditionalMetric
            [page]="reportPage"
            [widget]="widget"
            [dataset]="selectedDataset"
            [fields]="fields"
            [metricTypes]="metricTypes"
            [widgetDataForm]="widgetDataForm()"
          />
        }
      </div>
    </div>
  }

  <!-- Just To Save The Form -->
  <button
    class="wsm-btn wsm-btn-primary"
    [hidden]="true"
    type="submit"
    [disabled]="
      widgetDataForm().invalid ||
      (allowedWidget && widget?.metrics?.length! === 0) ||
      loading
    "
  >
    {{ "reporting.widget_editor.data_form.label_apply_button" | translate }}
  </button>
</form>
