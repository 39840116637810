import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Widget} from '../../../../models';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NgClass} from '@angular/common';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import {FontSizeInputComponent} from '../../../font-size-input/font-size-input.component';
import {FormControlPipe} from '../../../../pipes/form-control.pipe';

@Component({
  selector: 'app-widget-title',
  templateUrl: './widget-title.component.html',
  styleUrls: ['./widget-title.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    TranslateModule,
    FontSizeInputComponent,
    FormControlPipe,
  ],
})
export class WidgetTitleComponent implements OnInit, OnDestroy {
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() widgetStyleForm: UntypedFormGroup = new UntypedFormGroup({});
  @Input() isMetricLabel: boolean;

  // Properties
  subs: Subscription = new Subscription();

  ngOnInit(): void {
    this.setControls();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private setControls(): void {
    // Add Controls
    this.widgetStyleForm.addControl('labelBold', new UntypedFormControl(false));
    this.widgetStyleForm.addControl(
      'labelItalic',
      new UntypedFormControl(false)
    );
    this.widgetStyleForm.addControl(
      'labelAlign',
      new UntypedFormControl('left')
    );

    this.widgetStyleForm.updateValueAndValidity();

    //  Initialize Values
    this.widgetStyleForm
      .get('labelBold')
      ?.setValue(this.widget?.theme?.labelBold || false);
    this.widgetStyleForm
      .get('labelItalic')
      ?.setValue(this.widget?.theme?.labelItalic || false);
    this.widgetStyleForm
      .get('labelAlign')
      ?.setValue(
        this.widget?.theme?.labelAlign
          ? this.widget.theme.labelAlign
          : this.widget.widgetType === 'scorecard'
            ? 'center'
            : 'left'
      );

    // Listen Subs
    this.subs.add(
      this.widgetStyleForm
        .get('labelBold')
        ?.valueChanges.subscribe((selectedValue: boolean) => {
          this.widget.theme.labelBold = selectedValue;
        })
    );

    this.subs.add(
      this.widgetStyleForm
        .get('labelItalic')
        ?.valueChanges.subscribe((selectedValue: boolean) => {
          this.widget.theme.labelItalic = selectedValue;
        })
    );

    this.subs.add(
      this.widgetStyleForm
        .get('labelAlign')
        ?.valueChanges.subscribe((selectedValue: string) => {
          this.widget.theme.labelAlign = selectedValue;
        })
    );
  }

  setTitleStyle(type: string): void {
    let value: boolean;

    switch (type) {
      case 'bold':
        value = !this.widgetStyleForm.get('labelBold')?.value;
        this.widgetStyleForm.get('labelBold')?.setValue(value);
        this.widgetStyleForm.markAsDirty();
        break;
      case 'italic':
        value = !this.widgetStyleForm.get('labelItalic')?.value;
        this.widgetStyleForm.get('labelItalic')?.setValue(value);
        this.widgetStyleForm.markAsDirty();
        break;
      case 'left':
      case 'center':
      case 'right':
        this.widgetStyleForm.get('labelAlign')?.setValue(type);
        this.widgetStyleForm.markAsDirty();
        break;
    }
  }
}
