<form
  (ngSubmit)="onSaveWidget()"
  [formGroup]="widgetStyleForm"
  class="overflow-auto h-100 w-100 mx-0"
  id="widgetStyleForm"
>
  <mat-accordion multi>
    <!-- General Styles -->
    <mat-expansion-panel class="mb-1 mat-elevation-z0" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "reporting.widget_editor.style_form.title_general" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- General Controls -->
      <div class="row mx-0 pt-3">
        <!-- Title -->
        @if (
          widget.widgetType !== "image" &&
          widget.widgetType !== "scorecard" &&
          widget.widgetType !== "branding"
        ) {
          <div class="col-12 px-1 mb-3">
            <app-widget-title
              [widget]="widget"
              [widgetStyleForm]="widgetStyleForm"
              [isMetricLabel]="false"
            >
            </app-widget-title>
            <div class="wsm-input-group-prepend add-date-input w-100">
              <div class="date-divider"></div>
              <div
                class="add-date-btn"
                #drMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="dateRangeMenu"
              >
                <i class="fa-solid fa-calendar-range add-date"></i>
              </div>
              <input
                formControlName="title"
                type="text"
                class="wsm-input w-100"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_title_placeholder'
                    | translate
                }}"
              />
            </div>
            <mat-menu
              #dateRangeMenu="matMenu"
              xPosition="before"
              class="mat-menu-gray"
            >
              <ng-template matMenuContent>
                <app-dynamic-date-range
                  (dateChange)="addDynamicDate($event, 'title')"
                  [page]="reportPage"
                  [widget]="widget"
                >
                </app-dynamic-date-range>
              </ng-template>
            </mat-menu>
          </div>
        }

        <!-- Metric Label -->
        @if (widget.widgetType === "scorecard") {
          <div class="col-12 px-1 mb-3">
            <app-widget-title
              [widget]="widget"
              [widgetStyleForm]="widgetStyleForm"
              [isMetricLabel]="true"
            >
            </app-widget-title>
            <div class="wsm-input-group-prepend add-date-input w-100">
              <div class="date-divider"></div>
              <div
                class="add-date-btn"
                #drMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="dateRangeKpiMenu"
              >
                <i class="fa-solid fa-calendar-range add-date"></i>
              </div>
              <input
                formControlName="kpiLabel"
                type="text"
                class="wsm-input w-100"
                id="kpiLabel"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_metric_placeholder'
                    | translate
                }}"
              />
            </div>
            <mat-menu
              #dateRangeKpiMenu="matMenu"
              xPosition="before"
              class="mat-menu-gray"
            >
              <ng-template matMenuContent>
                <app-dynamic-date-range
                  (dateChange)="addDynamicDate($event, 'kpiLabel')"
                  [page]="reportPage"
                  [widget]="widget"
                >
                </app-dynamic-date-range>
              </ng-template>
            </mat-menu>
          </div>
        }

        <!-- Font Size -->
        @if (widget.widgetType !== "image" && widget.widgetType !== "title") {
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <app-font-size-input
              [fontSizeInput]="
                widgetStyleForm.controls['fontSize'] | formControl
              "
              [fontInputType]="'select'"
            />
          </div>
        }

        <!-- Background Color -->
        <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
          <label for="backgroundColor">{{
            "reporting.widget_editor.style_form.widget_bg_color" | translate
          }}</label>
          <div class="input-group">
            <input
              formControlName="backgroundColor"
              #backgroundColor
              id="backgroundColor"
              type="text"
              class="wsm-input w-100"
              placeholder="{{
                'reporting.widget_editor.style_form.widget_color_placeholder'
                  | translate
              }}"
              (ngModelChange)="onStyleControlChange($event, 'backgroundColor')"
            />
            <div
              class="circle-input rounded-circle"
              (colorPickerCancel)="onColorReset('backgroundColor')"
              (colorPickerChange)="onInputChange($event, 'backgroundColor')"
              [(colorPicker)]="backgroundColor.value"
              [cpCancelButton]="true"
              [cpDisabled]="disablePicker"
              [cpOKButton]="true"
              [style.background]="backgroundColor.value"
              [cpUseRootViewContainer]="true"
              [cpPositionRelativeToArrow]="true"
              [cpPresetColors]="presetColors"
              [cpMaxPresetColorsLength]="maxPresetColors"
              [cpAddColorButton]="true"
              [cpEyeDropper]="true"
              (cpPresetColorsChange)="changePresetColor($event)"
              cpCancelButtonClass="pickerOkBtn"
              cpCancelButtonText="Clear"
              cpOKButtonClass="pickerOkBtn"
              cpOKButtonText="Select"
            ></div>
          </div>
        </div>

        <!-- Border Color -->
        <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
          <label for="borderColor">{{
            "reporting.widget_editor.style_form.widget_border_color" | translate
          }}</label>
          <div class="input-group color-input w-100">
            <input
              #borderColor
              id="borderColor"
              class="wsm-input w-100"
              formControlName="borderColor"
              placeholder="{{
                'reporting.widget_editor.style_form.widget_color_placeholder'
                  | translate
              }}"
              type="text"
              (ngModelChange)="onStyleControlChange($event, 'borderColor')"
            />
            <div
              class="circle-input rounded-circle"
              (colorPickerCancel)="onColorReset('borderColor')"
              (colorPickerChange)="onInputChange($event, 'borderColor')"
              [(colorPicker)]="borderColor.value"
              [cpCancelButton]="true"
              [cpDisabled]="disablePicker"
              [cpOKButton]="true"
              [style.background]="borderColor.value"
              [cpUseRootViewContainer]="true"
              [cpPositionRelativeToArrow]="true"
              [cpPresetColors]="presetColors"
              [cpMaxPresetColorsLength]="maxPresetColors"
              [cpAddColorButton]="true"
              [cpEyeDropper]="true"
              (cpPresetColorsChange)="changePresetColor($event)"
              cpCancelButtonClass="pickerOkBtn"
              cpCancelButtonText="Clear"
              cpOKButtonClass="pickerOkBtn"
              cpOKButtonText="Select"
            ></div>
          </div>
        </div>

        <!-- Body Color -->
        @if (
          widget.widgetType !== "image" &&
          widget.widgetType !== "branding" &&
          widget.widgetType !== "title"
        ) {
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="bodyColor">{{
              "reporting.widget_editor.style_form.widget_body_color" | translate
            }}</label>
            <div class="input-group w-100">
              <input
                #bodyColor
                id="bodyColor"
                class="wsm-input w-100"
                formControlName="bodyColor"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_color_placeholder'
                    | translate
                }}"
                (ngModelChange)="onStyleControlChange($event, 'bodyColor')"
              />
              <div
                (colorPickerCancel)="onColorReset('bodyColor')"
                (colorPickerChange)="onInputChange($event, 'bodyColor')"
                [(colorPicker)]="bodyColor.value"
                [cpCancelButton]="true"
                [cpOKButton]="true"
                [style.background]="bodyColor.value"
                [cpUseRootViewContainer]="true"
                [cpPositionRelativeToArrow]="true"
                [cpPresetColors]="presetColors"
                [cpMaxPresetColorsLength]="maxPresetColors"
                [cpAddColorButton]="true"
                [cpEyeDropper]="true"
                (cpPresetColorsChange)="changePresetColor($event)"
                class="circle-input rounded-circle"
                cpCancelButtonClass="pickerOkBtn"
                cpCancelButtonText="Clear"
                cpOKButtonClass="pickerOkBtn"
                cpOKButtonText="Select"
              ></div>
            </div>
          </div>
        }

        <!-- Title Color -->
        @if (
          widget.widgetType !== "image" && widget.widgetType !== "branding"
        ) {
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="titleColor">{{
              "reporting.widget_editor.style_form.widget_title_color"
                | translate
            }}</label>
            <div class="input-group w-100">
              <input
                #titleColor
                id="titleColor"
                class="wsm-input w-100"
                formControlName="titleColor"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_color_placeholder'
                    | translate
                }}"
                (ngModelChange)="onStyleControlChange($event, 'titleColor')"
              />
              <div
                (colorPickerCancel)="onColorReset('titleColor')"
                (colorPickerChange)="onInputChange($event, 'titleColor')"
                [(colorPicker)]="titleColor.value"
                [cpCancelButton]="true"
                [cpOKButton]="true"
                [style.background]="titleColor.value"
                [cpUseRootViewContainer]="true"
                [cpPositionRelativeToArrow]="true"
                [cpPresetColors]="presetColors"
                [cpMaxPresetColorsLength]="maxPresetColors"
                [cpAddColorButton]="true"
                [cpEyeDropper]="true"
                (cpPresetColorsChange)="changePresetColor($event)"
                class="circle-input rounded-circle"
                cpCancelButtonClass="pickerOkBtn"
                cpCancelButtonText="Clear"
                cpOKButtonClass="pickerOkBtn"
                cpOKButtonText="Select"
              ></div>
            </div>
          </div>
        }

        <!-- Alignment -->
        @if (widget.widgetType !== "image" && widget.widgetType === "title") {
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="widgetTitleAlignment">
              {{
                "reporting.widget_editor.style_form.widget_alignment_label"
                  | translate
              }}&nbsp;<span class="required-mark">*</span>
            </label>
            <mat-select
              [panelClass]="'wsm-custom-select'"
              class="wsm-input w-100"
              disableOptionCentering
              formControlName="alignment"
              id="widgetTitleAlignment"
              placeholder="{{
                'reporting.widget_editor.style_form.widget_alignment_placeholder'
                  | translate
              }}"
            >
              @for (alignment of alignmentTypes; track alignment) {
                <mat-option [value]="alignment.value">
                  {{ alignment.translation | translate }}
                </mat-option>
              }
            </mat-select>
          </div>
        }
        <!-- Transparency -->
        <div class="col-12 col-md-6 col-xl-4 px-1 mb-3 d-flex align-items-end">
          <mat-slide-toggle
            formControlName="transparency"
            id="transparency"
            class="mb-2"
          >
            {{
              "reporting.widget_editor.style_form.widget_transparency"
                | translate
            }}
          </mat-slide-toggle>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
          <div class="row">
            @if (
              widget.widgetType === "scorecard" ||
              widget.widgetType === "table" ||
              widget.widgetType === "gallery"
            ) {
              <div class="col-lg-4 col-md-4 col-sm-12">
                <label for="schemaIndicator">
                  {{
                    "reporting.widget_editor.style_form.widget_color_schema"
                      | translate
                  }}
                </label>
                <mat-select
                  [panelClass]="'wsm-custom-select'"
                  class="wsm-input w-100"
                  disableOptionCentering
                  placeholder="{{
                    'shared.dialogs.plan_cancelation.feedback_options.select_option'
                      | translate
                  }}"
                  formControlName="schemaIndicator"
                  id="schemaIndicator"
                >
                  @for (item of [1, 2, 3, 4]; track item) {
                    <mat-option value="{{ 'opt-' + item }}">
                      {{
                        "reporting.widget_editor.style_form.color_schema_option"
                          | translate
                      }}&nbsp;{{ item }}
                    </mat-option>
                  }
                </mat-select>
              </div>
            }
            <div class="col-lg-4 col-md-4 col-sm-12 ps-1 pe-1">
              <app-fontawesome-icon-search
                [iconControl]="widgetStyleForm.get('widgetIcon')! | formControl"
              ></app-fontawesome-icon-search>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="widgetIconPosition">
                {{
                  "reporting.widget_editor.style_form.widget_icon_position"
                    | translate
                }}
              </label>
              <mat-select
                [panelClass]="'wsm-custom-select'"
                class="wsm-input w-100"
                disableOptionCentering
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_icon_position_placeholder'
                    | translate
                }}"
                formControlName="widgetIconPosition"
                id="widgetIconPosition"
              >
                <mat-option [value]="''">{{
                  "shared.dialogs.plan_cancelation.feedback_options.select_option"
                    | translate
                }}</mat-option>
                @for (item of widgetIconPositions; track item) {
                  <mat-option [value]="item">
                    {{
                      "reporting.widget_editor.style_form.widget_icon_position_" +
                        item | translate
                    }}
                  </mat-option>
                }
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Table Styles -->
    @if (widget.widgetType === "table") {
      <mat-expansion-panel class="mt-3 mb-1 mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "reporting.widget_editor.style_form.title_table" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row mx-0 pt-3">
          <!-- Header Background Color -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="tableHeaderBgColor">
              {{
                "reporting.widget_editor.style_form.table_bg_color_title"
                  | translate
              }}
            </label>
            <div class="input-group w-100">
              <input
                #tableHeaderBgColor
                class="wsm-input w-100"
                formControlName="tableHeaderBgColor"
                id="tableHeaderBgColor"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_color_placeholder'
                    | translate
                }}"
                (ngModelChange)="
                  onStyleControlChange($event, 'tableHeaderBgColor')
                "
              />
              <div
                (colorPickerCancel)="onColorReset('tableHeaderBgColor')"
                (colorPickerChange)="
                  onInputChange($event, 'tableHeaderBgColor')
                "
                [(colorPicker)]="tableHeaderBgColor.value"
                [cpCancelButton]="true"
                [cpOKButton]="true"
                [style.background]="tableHeaderBgColor.value"
                [cpUseRootViewContainer]="true"
                [cpPositionRelativeToArrow]="true"
                [cpPresetColors]="presetColors"
                [cpMaxPresetColorsLength]="maxPresetColors"
                [cpAddColorButton]="true"
                [cpEyeDropper]="true"
                (cpPresetColorsChange)="changePresetColor($event)"
                class="circle-input rounded-circle"
                cpCancelButtonClass="pickerOkBtn"
                cpCancelButtonText="Clear"
                cpOKButtonClass="pickerOkBtn"
                cpOKButtonText="Select"
                cpPosition="top"
              ></div>
            </div>
          </div>
          <!-- Header Text Color -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="tableHeaderTextColor">
              {{
                "reporting.widget_editor.style_form.table_text_color_title"
                  | translate
              }}
            </label>
            <div class="input-group w-100">
              <input
                #tableHeaderTextColor
                class="wsm-input w-100"
                formControlName="tableHeaderTextColor"
                id="tableHeaderTextColor"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_color_placeholder'
                    | translate
                }}"
                (ngModelChange)="
                  onStyleControlChange($event, 'tableHeaderTextColor')
                "
              />
              <div
                (colorPickerCancel)="onColorReset('tableHeaderTextColor')"
                (colorPickerChange)="
                  onInputChange($event, 'tableHeaderTextColor')
                "
                [(colorPicker)]="tableHeaderTextColor.value"
                [cpCancelButton]="true"
                [cpOKButton]="true"
                [style.background]="tableHeaderTextColor.value"
                [cpUseRootViewContainer]="true"
                [cpPositionRelativeToArrow]="true"
                [cpPresetColors]="presetColors"
                [cpMaxPresetColorsLength]="maxPresetColors"
                [cpAddColorButton]="true"
                [cpEyeDropper]="true"
                (cpPresetColorsChange)="changePresetColor($event)"
                class="circle-input rounded-circle"
                cpCancelButtonClass="pickerOkBtn"
                cpCancelButtonText="Clear"
                cpOKButtonClass="pickerOkBtn"
                cpOKButtonText="Select"
                cpPosition="top"
              ></div>
            </div>
          </div>
          <!-- Header Border Color -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="tableHeaderBorderColor">
              {{
                "reporting.widget_editor.style_form.table_header_border_title"
                  | translate
              }}
            </label>
            <div class="input-group w-100">
              <input
                #tableHeaderBorderColor
                class="wsm-input w-100"
                formControlName="tableHeaderBorderColor"
                id="tableHeaderBorderColor"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_color_placeholder'
                    | translate
                }}"
                (ngModelChange)="
                  onStyleControlChange($event, 'tableHeaderBorderColor')
                "
              />
              <div
                (colorPickerCancel)="onColorReset('tableHeaderBorderColor')"
                (colorPickerChange)="
                  onInputChange($event, 'tableHeaderBorderColor')
                "
                [(colorPicker)]="tableHeaderBorderColor.value"
                [cpCancelButton]="true"
                [cpOKButton]="true"
                [style.background]="tableHeaderBorderColor.value"
                [cpUseRootViewContainer]="true"
                [cpPositionRelativeToArrow]="true"
                [cpPresetColors]="presetColors"
                [cpMaxPresetColorsLength]="maxPresetColors"
                [cpAddColorButton]="true"
                [cpEyeDropper]="true"
                (cpPresetColorsChange)="changePresetColor($event)"
                class="circle-input rounded-circle"
                cpCancelButtonClass="pickerOkBtn"
                cpCancelButtonText="Clear"
                cpOKButtonClass="pickerOkBtn"
                cpOKButtonText="Select"
                cpPosition="top"
              ></div>
            </div>
          </div>
          <!-- Row Border Color -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="tableRowBorderColor">
              {{
                "reporting.widget_editor.style_form.table_row_border_color"
                  | translate
              }}
            </label>
            <div class="input-group w-100">
              <input
                #tableRowBorderColor
                class="wsm-input w-100"
                formControlName="tableRowBorderColor"
                id="tableRowBorderColor"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_color_placeholder'
                    | translate
                }}"
                (ngModelChange)="
                  onStyleControlChange($event, 'tableRowBorderColor')
                "
              />
              <div
                (colorPickerCancel)="onColorReset('tableRowBorderColor')"
                (colorPickerChange)="
                  onInputChange($event, 'tableRowBorderColor')
                "
                [(colorPicker)]="tableRowBorderColor.value"
                [cpCancelButton]="true"
                [cpOKButton]="true"
                [style.background]="tableRowBorderColor.value"
                [cpUseRootViewContainer]="true"
                [cpPositionRelativeToArrow]="true"
                [cpPresetColors]="presetColors"
                [cpMaxPresetColorsLength]="maxPresetColors"
                [cpAddColorButton]="true"
                [cpEyeDropper]="true"
                (cpPresetColorsChange)="changePresetColor($event)"
                class="circle-input rounded-circle"
                cpCancelButtonClass="pickerOkBtn"
                cpCancelButtonText="Clear"
                cpOKButtonClass="pickerOkBtn"
                cpOKButtonText="Select"
                cpPosition="top"
              ></div>
            </div>
          </div>
          <!-- Row Background Color -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="tableRowBgColor">
              {{
                "reporting.widget_editor.style_form.table_row_bg_color"
                  | translate
              }}
            </label>
            <div class="input-group w-100">
              <input
                #tableRowBgColor
                class="wsm-input w-100"
                formControlName="tableRowBgColor"
                id="tableRowBgColor"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_color_placeholder'
                    | translate
                }}"
                (ngModelChange)="
                  onStyleControlChange($event, 'tableRowBgColor')
                "
              />
              <div
                (colorPickerCancel)="onColorReset('tableRowBgColor')"
                (colorPickerChange)="onInputChange($event, 'tableRowBgColor')"
                [(colorPicker)]="tableRowBgColor.value"
                [cpCancelButton]="true"
                [cpOKButton]="true"
                [style.background]="tableRowBgColor.value"
                [cpUseRootViewContainer]="true"
                [cpPositionRelativeToArrow]="true"
                [cpPresetColors]="presetColors"
                [cpMaxPresetColorsLength]="maxPresetColors"
                [cpAddColorButton]="true"
                [cpEyeDropper]="true"
                (cpPresetColorsChange)="changePresetColor($event)"
                class="circle-input rounded-circle"
                cpCancelButtonClass="pickerOkBtn"
                cpCancelButtonText="Clear"
                cpOKButtonClass="pickerOkBtn"
                cpOKButtonText="Select"
                cpPosition="top"
              ></div>
            </div>
          </div>
          <!-- Player Style -->
          @if (recordExists) {
            <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
              <label for="playerStyle">
                {{
                  "reporting.widget_editor.style_form.table_player_style_label"
                    | translate
                }}
              </label>
              <mat-select
                [panelClass]="'wsm-custom-select'"
                class="wsm-input w-100"
                disableOptionCentering
                formControlName="playerStyle"
                id="playerStyle"
                placeholder="{{
                  'reporting.widget_editor.style_form.table_player_style_placeholder'
                    | translate
                }}"
              >
                <mat-option [value]="'classic'">{{
                  "reporting.widget_editor.style_form.table_option_classic"
                    | translate
                }}</mat-option>
                <mat-option [value]="'modern'">{{
                  "reporting.widget_editor.style_form.table_option_modern"
                    | translate
                }}</mat-option>
              </mat-select>
            </div>
          }
          <!-- Striped Rows -->
          <div
            class="col-12 col-md-6 col-xl-4 px-1 mb-3 d-flex align-items-end"
          >
            <mat-slide-toggle
              formControlName="stripedRows"
              id="stripedRows"
              class="mb-2"
            >
              {{
                "reporting.widget_editor.style_form.table_striped_row"
                  | translate
              }}
            </mat-slide-toggle>
          </div>
        </div>
      </mat-expansion-panel>
    }

    <!-- Chart Styles -->
    @if (
      widget.widgetType === "chart" ||
      widget.widgetType === "biggestchanges" ||
      widget.widgetType === "current-goal" ||
      widget.widgetType === "gauge" ||
      widget.widgetType === "timeline"
    ) {
      <mat-expansion-panel class="mb-1 mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "reporting.widget_editor.style_form.title_chart" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Chart Controls -->
        <div class="row mx-0 pt-3">
          <!--Goal Tracker | current vs goal-->
          @if (widget.widgetType === "current-goal") {
            <div class="col-lg-6 col-md-6 col-sm-12">
              <label for="resultValueLabel">{{
                "reporting.widget_editor.style_form.current_goal_results_value_label"
                  | translate
              }}</label>
              <input
                formControlName="resultValueLabel"
                type="text"
                class="wsm-input w-100"
                id="resultValueLabel"
                placeholder="{{
                  'reporting.widget_editor.style_form.current_goal_results_value_placeholder'
                    | translate
                }}"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <label for="goalAmountLabel">
                {{
                  "reporting.widget_editor.style_form.current_goal_goal_amount_label"
                    | translate
                }}
              </label>
              <input
                formControlName="goalAmountLabel"
                type="text"
                class="wsm-input w-100"
                id="goalAmountLabel"
                placeholder="{{
                  'reporting.widget_editor.style_form.current_goal_goal_amount_placeholder'
                    | translate
                }}"
              />
            </div>
          }
          <!-- Legend Position -->
          @if (widget.widgetType === "biggestchanges") {
            <div class="col-12 col-md-6 col-xl-4">
              <label for="placeValuesInside">
                {{
                  "reporting.widget_editor.style_form.chart_label_legend_position"
                    | translate
                }}
              </label>
              <mat-select
                [panelClass]="'wsm-custom-select'"
                class="wsm-input"
                disableOptionCentering
                formControlName="placeValuesInside"
                id="placeValuesInside"
                placeholder="{{
                  'reporting.widget_editor.style_form.chart_placeholder_legend'
                    | translate
                }}"
              >
                <mat-option [value]="0">
                  {{
                    "reporting.widget_editor.style_form.chart_legend_outside"
                      | translate
                  }}
                </mat-option>
                <mat-option [value]="1">
                  {{
                    "reporting.widget_editor.style_form.chart_legend_inside"
                      | translate
                  }}
                </mat-option>
              </mat-select>
            </div>
          }
          @if (widget.widgetType === "chart") {
            <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
              <label for="legendPosition">
                {{
                  "reporting.widget_editor.style_form.chart_label_legend_position"
                    | translate
                }}
              </label>
              <mat-select
                [panelClass]="'wsm-custom-select'"
                class="wsm-input"
                disableOptionCentering
                formControlName="legendPosition"
                id="legendPosition"
                placeholder="{{
                  'reporting.widget_editor.style_form.chart_placeholder_legend'
                    | translate
                }}"
              >
                <mat-option [value]="'top'">
                  {{
                    "reporting.widget_editor.style_form.chart_legend_top"
                      | translate
                  }}
                </mat-option>
                <mat-option [value]="'bottom'">
                  {{
                    "reporting.widget_editor.style_form.chart_legend_bottom"
                      | translate
                  }}
                </mat-option>
                <mat-option [value]="'right'">
                  {{
                    "reporting.widget_editor.style_form.chart_legend_right"
                      | translate
                  }}
                </mat-option>
                <mat-option [value]="'left'">
                  {{
                    "reporting.widget_editor.style_form.chart_legend_left"
                      | translate
                  }}
                </mat-option>
                <mat-option [value]="'hidden'">
                  {{
                    "reporting.widget_editor.style_form.chart_legend_hidden"
                      | translate
                  }}
                </mat-option>
              </mat-select>
            </div>
          }
          @if (widget.widgetType === "biggestchanges") {
            <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
              <label for="valueToShow">
                {{
                  "reporting.widget_editor.style_form.chart_value_to_show"
                    | translate
                }}
              </label>
              <mat-select
                [panelClass]="'wsm-custom-select'"
                class="wsm-input"
                disableOptionCentering
                formControlName="valueToShow"
                id="valueToShow"
                placeholder="{{
                  'reporting.widget_editor.style_form.chart_placeholder_value_show'
                    | translate
                }}"
              >
                <mat-option [value]="'delta'">Delta</mat-option>
                <mat-option [value]="'percent'">Delta Percent</mat-option>
              </mat-select>
            </div>
          }
          <!-- Data Label -->
          @if (
            widget?.chartData?.chartType === "pie2d" ||
            widget?.chartData?.chartType === "doughnut2d"
          ) {
            <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
              <label for="legendPosition">
                {{
                  "reporting.widget_editor.style_form.chart_label_data"
                    | translate
                }}
              </label>
              <mat-select
                [panelClass]="'wsm-custom-select'"
                class="wsm-input"
                disableOptionCentering
                formControlName="dataLabel"
                id="dataLabel"
                placeholder="{{
                  'reporting.widget_editor.style_form.chart_placeholder_data_label'
                    | translate
                }}"
              >
                <mat-option [value]="'metric'"
                  >{{
                    "reporting.widget_editor.style_form.chart_option_metric_name"
                      | translate
                  }}
                </mat-option>
                <mat-option [value]="'dimension'"
                  >{{
                    "reporting.widget_editor.style_form.chart_option_dimension_value"
                      | translate
                  }}
                </mat-option>
              </mat-select>
            </div>
          }
          <!-- Data Value -->
          @if (
            widget?.chartData?.chartType === "pie2d" ||
            widget?.chartData?.chartType === "doughnut2d" ||
            widget?.chartData?.chartType === "funnel"
          ) {
            <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
              <label for="dataValue">
                {{
                  "reporting.widget_editor.style_form.chart_label_data_value"
                    | translate
                }}
              </label>
              <mat-select
                [panelClass]="'wsm-custom-select'"
                class="wsm-input"
                disableOptionCentering
                formControlName="dataValue"
                id="dataValue"
                placeholder="{{
                  'reporting.widget_editor.style_form.chart_placeholder_data_value'
                    | translate
                }}"
              >
                <mat-option [value]="'metricValue'">
                  {{
                    "reporting.widget_editor.style_form.chart_metric_value_option"
                      | translate
                  }}
                </mat-option>
                <mat-option [value]="'percentageTotal'">
                  {{
                    "reporting.widget_editor.style_form.chart_percentage_total_option"
                      | translate
                  }}
                </mat-option>
              </mat-select>
            </div>
          }
          <!-- Show Values In Data Plot -->
          @if (widget?.chartData?.chartType === "doughnut2d") {
            <div class="col-12 px-1 mb-3 d-flex align-items-end">
              <mat-slide-toggle
                formControlName="dataPlot"
                id="dataPlot"
                class="mb-2"
              >
                {{
                  "reporting.widget_editor.style_form.chart_show_data_plot"
                    | translate
                }}
              </mat-slide-toggle>
            </div>
          }
          @if (widget?.widgetType === "biggestchanges") {
            <div class="col-12 px-1 mb-3 d-flex align-items-end">
              <mat-slide-toggle
                formControlName="hideXAxisName"
                id="hideXAxisName"
                class="mb-2"
              >
                {{
                  "reporting.widget_editor.style_form.chart_hide_x_axis"
                    | translate
                }}
              </mat-slide-toggle>
            </div>
          }
          @if (
            widget?.widgetType !== "biggestchanges" &&
            widget?.widgetType !== "current-goal" &&
            widget?.widgetType !== "gauge" &&
            widget?.chartData?.chartType !== "pie2d" &&
            widget?.chartData?.chartType !== "doughnut2d" &&
            widget?.chartData?.chartType !== "funnel"
          ) {
            <!-- Show Axis Values -->
            @if (widget.widgetType !== "timeline") {
              <div class="col-12 px-1 mb-3 d-flex align-items-end">
                <mat-slide-toggle
                  formControlName="showAxis"
                  id="showAxis"
                  class="mb-2"
                >
                  {{
                    "reporting.widget_editor.style_form.chart_show_axis"
                      | translate
                  }}
                </mat-slide-toggle>
              </div>
            }
            <!-- Hide X Axis Name -->
            @if (widget.widgetType !== "timeline") {
              <div class="col-12 px-1 mb-3 d-flex align-items-end">
                <mat-slide-toggle
                  formControlName="hideXAxisName"
                  id="hideXAxisName"
                  class="mb-2"
                >
                  {{
                    "reporting.widget_editor.style_form.chart_hide_x_axis"
                      | translate
                  }}
                </mat-slide-toggle>
              </div>
            }
            <!-- Hide Y Axis Name -->
            @if (widget?.chartData?.chartType === "mscombidy2d") {
              <div class="col-12 px-1 mb-3 d-flex align-items-end">
                <mat-slide-toggle
                  formControlName="hideYAxisName"
                  id="hideYAxisName"
                  class="mb-2"
                >
                  {{
                    "reporting.widget_editor.style_form.chart_hide_y_axis"
                      | translate
                  }}
                </mat-slide-toggle>
              </div>
            }
            <!-- Use Cumulative Values -->
            <div class="col-12 px-1 mb-3 d-flex align-items-end">
              <mat-slide-toggle
                formControlName="showCumulative"
                id="showCumulative"
                class="mb-2"
              >
                {{
                  "reporting.widget_editor.style_form.chart_cumulative_values"
                    | translate
                }}
              </mat-slide-toggle>
            </div>
          }
          <!--Goal Tracker | Gauge - show percentage-->
          @if (widget.widgetType === "gauge") {
            <div class="col-12 px-1 mb-3 d-flex align-items-end">
              <mat-slide-toggle
                formControlName="showPercentage"
                id="showCumulative"
                class="mb-2"
              >
                {{
                  "reporting.widget_editor.style_form.gauge_show_percentage"
                    | translate
                }}
              </mat-slide-toggle>
            </div>
          }
        </div>
      </mat-expansion-panel>
    }

    <!-- Image Styles -->
    @if (widget.widgetType === "image") {
      <mat-expansion-panel class="mb-1 mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "reporting.widget_editor.style_form.title_image" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Image Controls -->
        <div class="row mx-0 pt-3" [formGroup]="imageDataForm">
          <!-- Image File Or URL -->
          <div
            [ngClass]="invalidUrl || errorLoading ? '' : 'mb-3'"
            class="position-relative col-12 px-0"
          >
            <label for="imageSrc">{{
              "reporting.widget_editor.style_form.image_label_source"
                | translate
            }}</label>
            @if (!isFile) {
              <div class="d-flex">
                <input
                  autocomplete="off"
                  class="wsm-input img-input w-100"
                  formControlName="imageSrc"
                  id="imageSrc"
                  placeholder="{{
                    'reporting.widget_editor.style_form.image_input_src_placeholder'
                      | translate
                  }}"
                />
                <button
                  (click)="imageInput.click()"
                  class="wsm-btn upload-btn"
                  type="button"
                >
                  {{
                    "reporting.widget_editor.style_form.image_upload_button"
                      | translate
                  }}
                </button>
              </div>
            }
            @if (
              widgetStyleForm?.get("imageData")?.invalid &&
              widgetStyleForm?.get("imageData")?.get("imageSrc")?.dirty
            ) {
              <div>
                @if (
                  widgetStyleForm?.get("imageData")?.get("imageSrc")?.errors
                    ?.invalidUrl
                ) {
                  <div>
                    <span class="d-block mb-1 wsm-text-grayed">
                      {{
                        "reporting.widget_editor.style_form.image_provide_url_message"
                          | translate
                      }}
                    </span>
                  </div>
                }
                @if (
                  widgetStyleForm?.get("imageData")?.get("imageSrc")?.errors
                    ?.errorLoading
                ) {
                  <div>
                    <span class="d-block mb-1 wsm-text-grayed">
                      {{
                        "reporting.widget_editor.style_form.image_error_load_message"
                          | translate
                      }}
                    </span>
                  </div>
                }
              </div>
            }
            @if (isFile) {
              <div class="position-relative image-file-container d-flex">
                <input
                  [disabled]="true"
                  [value]="
                    widgetStyleForm?.get('imageData')?.get('fileName')?.value
                  "
                  class="wsm-input img-input w-100"
                />
                <button
                  (click)="imageInput.click()"
                  class="wsm-btn upload-btn"
                  type="button"
                >
                  {{
                    "reporting.widget_editor.style_form.image_upload_button"
                      | translate
                  }}
                </button>
                <button
                  (click)="removeImage()"
                  class="wsm-remove-btn"
                  title="{{
                    'reporting.widget_editor.style_form.image_remove_button'
                      | translate
                  }}"
                  type="button"
                >
                  <i class="fa-regular fa-xmark"></i>
                </button>
              </div>
            }
            <input
              #imageInput
              (change)="handleFileInput($event)"
              [hidden]="true"
              accept="image/*"
              type="file"
            />
          </div>
          <!-- Image Size -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="imageFit">
              {{
                "reporting.widget_editor.style_form.image_sizing" | translate
              }}
            </label>
            <mat-select
              [panelClass]="'wsm-custom-select'"
              class="wsm-input"
              disableOptionCentering
              formControlName="imageFit"
              id="imageFit"
              placeholder="{{
                'reporting.widget_editor.style_form.image_size_placeholder'
                  | translate
              }}"
            >
              @for (size of imageSizeFilters; track size) {
                <mat-option [value]="size.property">
                  {{ size.translation | translate }}
                </mat-option>
              }
            </mat-select>
          </div>
        </div>
      </mat-expansion-panel>
    }

    <!-- Branding Styles -->
    @if (widget.widgetType === "branding") {
      <mat-expansion-panel class="mb-1 mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{
              "reporting.widget_editor.style_form.title_branding" | translate
            }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Branding Controls -->
        <div class="row mx-0 pt-3">
          <!-- Logo Position -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="brandingStyle">
              {{
                "reporting.widget_editor.style_form.branding_logo_label"
                  | translate
              }}
            </label>
            <mat-select
              [panelClass]="'wsm-custom-select'"
              class="wsm-input"
              disableOptionCentering
              formControlName="brandingStyle"
              id="brandingStyle"
              placeholder="{{
                'reporting.widget_editor.style_form.branding_logo_placeholder'
                  | translate
              }}"
            >
              <mat-option [value]="'left'">
                {{
                  "reporting.widget_editor.style_form.branding_logo_left"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'center'">
                {{
                  "reporting.widget_editor.style_form.branding_logo_center"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'right'">
                {{
                  "reporting.widget_editor.style_form.branding_logo_right"
                    | translate
                }}
              </mat-option>
            </mat-select>
          </div>
          <!-- Exclude from Pages -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="excludedPages">
              {{
                "reporting.widget_editor.style_form.branding_excluded_label"
                  | translate
              }}
            </label>
            <mat-select
              [panelClass]="'wsm-custom-select'"
              class="wsm-input"
              disableOptionCentering
              formControlName="excludedPages"
              id="excludedPages"
              multiple
              placeholder="{{
                'reporting.widget_editor.style_form.branding_excluded_placeholder'
                  | translate
              }}"
            >
              @for (d of pages; track d) {
                <mat-option [disabled]="d.id === page.id" [value]="d.id">
                  {{ d.name }}
                </mat-option>
              }
            </mat-select>
          </div>
          <!-- Text Color -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="websiteColor">{{
              "reporting.widget_editor.style_form.widget_branding_color_label"
                | translate
            }}</label>
            <div class="input-group w-100">
              <input
                #websiteColor
                class="wsm-input w-100"
                formControlName="websiteColor"
                id="websiteColor"
                placeholder="{{
                  'reporting.widget_editor.style_form.widget_color_placeholder'
                    | translate
                }}"
                type="text"
              />
              <div
                (colorPickerCancel)="onColorReset('websiteColor')"
                (colorPickerChange)="onInputChange($event, 'websiteColor')"
                [(colorPicker)]="websiteColor.value"
                [cpCancelButton]="true"
                [cpOKButton]="true"
                [style.background]="websiteColor.value"
                [cpUseRootViewContainer]="true"
                [cpPositionRelativeToArrow]="true"
                [cpPresetColors]="presetColors"
                [cpMaxPresetColorsLength]="maxPresetColors"
                [cpAddColorButton]="true"
                [cpEyeDropper]="true"
                (cpPresetColorsChange)="changePresetColor($event)"
                class="circle-input rounded-circle"
                cpCancelButtonClass="pickerOkBtn"
                cpCancelButtonText="Clear"
                cpOKButtonClass="pickerOkBtn"
                cpOKButtonText="Select"
              ></div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    }

    <!-- Gallery Styles -->
    @if (widget.widgetType === "gallery") {
      <mat-expansion-panel class="mb-1 mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "reporting.widget_editor.style_form.title_gallery" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Gallery Controls -->
        <div class="row mx-0 pt-3">
          <!-- Dark Mode -->
          <div class="col-12 col-md-6 col-xl-4 px-1 mb-3">
            <label for="darkMode">
              {{
                "reporting.widget_editor.style_form.gallery_dark_label"
                  | translate
              }}
            </label>
            <mat-button-toggle-group
              appearance="legacy"
              aria-label="Dark Mode"
              id="darkMode"
              formControlName="darkMode"
            >
              <mat-button-toggle class="ps-1" value="on">
                {{
                  "reporting.widget_editor.style_form.gallery_dark_on"
                    | translate
                }}
              </mat-button-toggle>
              <mat-button-toggle class="pe-1" value="off">
                {{
                  "reporting.widget_editor.style_form.gallery_dark_off"
                    | translate
                }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </mat-expansion-panel>
    }

    <!-- Shape Styles -->
    @if (
      widget.widgetType === "scorecard" ||
      widget.widgetType === "image" ||
      widget.widgetType === "container" ||
      (widget.widgetType === "dynamictext" &&
        widget.dynamicTextEdit === "basic")
    ) {
      <mat-expansion-panel class="mb-1 mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "reporting.widget_editor.style_form.title_shape" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-widget-shapes
          [widgetStyleForm]="widgetStyleForm"
          [widget]="widget"
        ></app-widget-shapes>
      </mat-expansion-panel>
    }
  </mat-accordion>

  <!-- Just For Save The Form -->
  <button
    class="wsm-btn wsm-btn-primary"
    [hidden]="true"
    type="submit"
    [disabled]="widgetStyleForm.invalid || loading"
  >
    Apply
  </button>
</form>
