<!--BEGIN FOR BODY CONTENT-->
<div [hidden]="fontInputType() === 'button'" class="w-100 font-size-input-box">
  <span class="fsi-label">
    {{
      "reporting.widget_editor.style_form.branding_font_size_label" | translate
    }}</span
  >
  <div
    id="fontSizeInputTrigger"
    #fontSizeInputTriggerEl
    cdkOverlayOrigin
    #triggerInput="cdkOverlayOrigin"
    (click)="onShowFontSizeMenu()"
    class="fsi-input wsm-input d-flex align-items-center justify-content-between"
  >
    @if (fontSizeInput?.value?.size) {
      <span class="d-flex align-items-center gap-2 fsi-item">
        <span> {{ fontSizeInput?.value?.translation | translate }}</span>
        <i class="fa-solid fa-circle"></i>
        <span>{{ fontSizeInput?.value?.sizeLabel }}</span>
      </span>
    }
    @if (!fontSizeInput?.value?.size) {
      <span>{{
        "reporting.widget_editor.style_form.theme_default_option" | translate
      }}</span>
    }
    <i class="fa-solid fa-caret-down"></i>
  </div>
</div>
<!--END FOR BODY CONTENT-->

<!--BEGIN FOR TITLE CONTENT-->
<button
  [hidden]="fontInputType() === 'select'"
  id="fontSizeButtonTrigger"
  #fontSizeInputTriggerEl
  cdkOverlayOrigin
  #triggerButton="cdkOverlayOrigin"
  (click)="onShowFontSizeMenu()"
  class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon custom-tiny me-1"
  type="button"
  [ngClass]="{
    'active-style': fontSizeInput?.value?.size,
  }"
>
  <i class="fa-regular fa-text-size fa-xs"></i>
</button>
<!--END FRON TITLE CONTENT-->

<ng-template
  #fontSizeMenuOverlay
  cdkConnectedOverlay
  (backdropClick)="onHideFontSizeMenu()"
  [cdkConnectedOverlayOrigin]="
    fontInputType() === 'select' ? triggerInput : triggerButton
  "
  [cdkConnectedOverlayOpen]="showFontSizeOptions()"
  [cdkConnectedOverlayWidth]="
    fontInputType() === 'select' ? fontSizeInputTriggerEl.clientWidth : '215px'
  "
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayPanelClass]="[
    'wsm-bg-primary',
    'rounded-3',
    'wsm-mat-shadow',
  ]"
>
  <div class="w-100 d-flex flex-column">
    <mat-radio-group
      [formControl]="fontSizeSelectedControl"
      (change)="onChangeSelection($event)"
      class="d-flex flex-column align-items-start ms-2"
    >
      @for (fs of fontSizeOptions(); track $index) {
        <mat-radio-button
          [checked]="fontSizeInput?.value?.size === fs.size"
          [value]="fs"
        >
          <span class="d-flex align-items-center gap-2 fsi-item">
            <span> {{ fs.translation | translate }}</span>
            <i class="fa-solid fa-circle"></i>
            <span>{{ fs.sizeLabel }}</span>
          </span>
        </mat-radio-button>
      }
      <mat-radio-button
        [checked]="isCustomFontSize() && fontSizeInput.value.size"
        [value]="customFontSizeObject()"
      >
        <span class="fsi-item">
          <span class="me-1">
            {{ customFontSizeObject().translation | translate }}
          </span>
          <input
            (click)="$event.stopPropagation()"
            (keypress)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
            [formControl]="customFontSizeControl"
            class="wsm-input fsi-custom-input me-1 validate"
            type="number"
          />
          <sup
            matTooltip="{{
              'reporting.widget_editor.style_form.branding_custom_font_size_tooltip'
                | translate
            }}"
            matTooltipClass="wsm-tooltip tooltip-below"
            ><i class="fa-regular fa-circle-info"></i
          ></sup>
        </span>
      </mat-radio-button>
    </mat-radio-group>
    <div class="w-100 d-flex justify-content-end p-1">
      <button
        (click)="onApplyFontSize()"
        class="wsm-btn wsm-btn-primary wsm-btn-small"
      >
        {{ "reporting.widget_editor.apply_button" | translate }}
      </button>
    </div>
  </div>
</ng-template>
