import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  inject,
  output,
  signal,
} from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {
  distinctUntilChanged,
  lastValueFrom,
  Observable,
  Subscription,
} from 'rxjs';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {formatDate, NgClass, NgStyle} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';
import {
  MatCard,
  MatCardHeader,
  MatCardTitle,
  MatCardContent,
  MatCardFooter,
} from '@angular/material/card';
import {WidgetErrorComponent} from '../widget-error/widget-error.component';
import {KeywordComponent} from '../widget-list/keyword/keyword.component';
import {HeadingComponent} from '../widget-list/heading/heading.component';
import {ScorecardComponent} from '../widget-list/scorecard/scorecard.component';
import {RichTextComponent} from '../widget-list/rich-text/rich-text.component';
import {ImageComponent} from '../widget-list/image/image.component';
import {BrandingComponent} from '../widget-list/branding/branding.component';
import {AdGalleryComponent} from '../widget-list/ad-gallery/ad-gallery.component';
import {ChartComponent} from '../widget-list/chart/chart.component';
import {TableComponent} from '../widget-list/table/table.component';
import {BiggestChangesComponent} from '../widget-list/biggest-changes/biggest-changes.component';
import {GoalTrackerComponent} from '../widget-list/goal-tracker/goal-tracker.component';
import {GeoHeatMapComponent} from '../widget-list/geo-heat-map/geo-heat-map.component';
import {LoadingComponent} from '../../loading/loading.component';
import {WidgetActionComponent} from '../widget-action/widget-action.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {DatasetStoreSignal} from 'src/app/modules/reporting/state/dataset.store';
import {
  DynamicDate,
  EditWidgetConfigData,
  Widget,
  WidgetAction,
  WidgetSort,
} from 'src/app/shared/models/widget/widget.model';
import {
  DateRange,
  ReportDateRange,
  ReportPage,
} from 'src/app/shared/models/report/report.model';
import {ReportTheme} from 'src/app/shared/models/report/report-theme.model';
import {SharedAuth} from 'src/app/shared/models/share-report.model';
import {CustomError} from 'src/app/shared/models/custom-error.model';
import {ReportService} from 'src/app/core/services/report.service';
import {WidgetService} from 'src/app/core/services/widget.service';
import {ShareReportService} from 'src/app/core/services/share-report.service';
import {NotificationService} from 'src/app/core/services/notification.service';
import {MobileService} from 'src/app/core/services/mobile.service';
import {HelperService} from 'src/app/shared/helpers/helper.service';
import {ScopeService} from 'src/app/shared/helpers/scope.service';
import {ColorService} from 'src/app/shared/helpers/color.service';
import {ReportPageStoreSignal} from 'src/app/modules/reporting/state/report-page.store';
import {toObservable} from '@angular/core/rxjs-interop';
import {CdkContextMenuTrigger, CdkMenuTrigger} from '@angular/cdk/menu';
import {IWidgetContextMenu} from '../../../models';
import {DialogService} from '../../../../core/services';
import {MatDialogConfig} from '@angular/material/dialog';
import {SaveAsTemplateDialogComponent} from '../../../dialogs';
import {Clipboard} from '@angular/cdk/clipboard';
import {propertiesToCopy} from '../../../helpers';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  standalone: true,
  imports: [
    MatTooltip,
    MatCard,
    NgClass,
    NgStyle,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    WidgetErrorComponent,
    KeywordComponent,
    HeadingComponent,
    ScorecardComponent,
    RichTextComponent,
    ImageComponent,
    BrandingComponent,
    AdGalleryComponent,
    ChartComponent,
    TableComponent,
    BiggestChangesComponent,
    GoalTrackerComponent,
    GeoHeatMapComponent,
    LoadingComponent,
    MatCardFooter,
    MatPaginator,
    WidgetActionComponent,
    TranslateModule,
    CdkContextMenuTrigger,
  ],
})
export class WidgetComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('widgetCardElement', {read: ElementRef, static: true})
  widgetCard: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(CdkContextMenuTrigger) contextMenuTrigger: CdkContextMenuTrigger;

  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() page: ReportPage;
  @Input() pages: Array<ReportPage>;
  @Input() reportTheme: ReportTheme;
  @Input() pageTheme: ReportTheme;
  @Input() widgetStyleForm: FormGroup;
  @Input() reportId: string;
  @Input() isEditMode: boolean;
  @Input() isFromEditor = false;
  @Input() isPrint: boolean;
  @Input() mobileStatus: {isMobile: boolean; largeMobile: boolean};
  @Input() orientation: string;
  @Input() eventMove: Observable<any>;
  @Output() widgetSortChanged = new EventEmitter();
  @Output() positionChanged = new EventEmitter();
  @Output() actionsActive: EventEmitter<WidgetAction> =
    new EventEmitter<WidgetAction>();
  editWidgetEmitter = output<EditWidgetConfigData>();

  public userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
  // Properties
  theme: ReportTheme = {};
  sharedAuth: SharedAuth;
  subs: Subscription = new Subscription();
  timer: any;

  // State
  isLoading = false;
  showActions = signal<boolean>(false);
  largeMobile: boolean;
  isMobile: boolean;
  isExternalUser: boolean;
  isMovingItem = false;

  isEditTitle = false;
  isPresentationMode = false;
  presentationFontSize: string;

  private datasetStoreSignal = inject(DatasetStoreSignal);
  private reportPageStore = inject(ReportPageStoreSignal);
  private getPageActive$ = toObservable(this.reportPageStore.activePage);

  constructor(
    private router: Router,
    private clipboard: Clipboard,
    private translate: TranslateService,
    private reportService: ReportService,
    private widgetService: WidgetService,
    private sharedService: ShareReportService,
    private helperService: HelperService,
    private notificationService: NotificationService,
    private mobileService: MobileService,
    private scopeService: ScopeService,
    private colorService: ColorService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.isExternalUser = JSON.parse(
      localStorage.getItem('currentUser') as string
    )?.roles?.includes('ROLE_EXTERNAL');
    this.isMobile = this.mobileStatus?.isMobile;

    if (this.isMobile) {
      this.subs.add(
        this.mobileService.mobileWindow$.subscribe((res: string) => {
          this.largeMobile = res === 'large';
        })
      );
    }

    if (!this.widget.recordsPerPage) {
      this.widget.recordsPerPage = 10;
    }
    if (this.isMobile) {
      this.widget.recordsPerPage = 5;
    }
    if (!this.widget.pageNumber) {
      this.widget.pageNumber = 1;
    }

    this.sharedAuth = JSON.parse(
      sessionStorage.getItem('sharedAuth') as string
    );
    this.isEditMode = this.widgetService.getEditMode();
    this.setTheme();
    if (
      this.widget.widgetType !== 'image' &&
      this.widget.widgetType !== 'branding'
    ) {
      this.updateDynamicDates();
    }

    this.subs.add(
      this.eventMove?.subscribe((value: any): void => {
        if (this.widget?.id === value?.widget?.widget?.id) {
          this.isMovingItem = value.move;
          this.onWidgetActions(this.isMovingItem ? 'move' : 'endMove');
        }
      })
    );

    // Widget title edit inline
    this.subs.add(
      this.widgetService.editTitleWidgetTitle$.subscribe({
        next: (res) => {
          this.isEditTitle = res.isEdit
            ? this.widget.id === res.widgetId
            : false;
        },
      })
    );

    // Open widget context menu
    this.subs.add(
      this.widgetService.widgetContextMenu$.subscribe({
        next: (res: IWidgetContextMenu | null) => {
          if (res && res?.widget && res?.widget?.id === this.widget?.id) {
            this.onContextMenu(res);
          }
        },
      })
    );

    this.subs.add(
      this.widgetService.widgetHasBeenEdited$
        .pipe(distinctUntilChanged())
        .subscribe((widget) => {
          this.refreshWidgetAfterEdit(widget);
        })
    );

    this.subs.add(
      this.reportService.presentationMode.subscribe((value: boolean): void => {
        if (value && this.theme?.fontSize) {
          const currentFontSize =
            this.widget.widgetType === 'title'
              ? this.theme.titleFontSize || this.theme.fontSize
              : this.theme?.fontSize;
          const numericPart = parseInt(currentFontSize, 10);
          const newSize = numericPart + 2;
          this.presentationFontSize = `${newSize}px`;
        } else {
          this.presentationFontSize = this.theme?.fontSize || '';
        }
        this.isPresentationMode = value;
      })
    );
  }

  ngAfterViewInit(): void {
    this.addShapeClassName();

    if (this.paginator) {
      this.subs.add(
        this.paginator.page.subscribe((event: PageEvent): void => {
          this.widget.pageNumber = event.pageIndex + 1;
          this.widget.recordsPerPage = event.pageSize;
          this.onWidgetSortChanged({widget: this.widget, type: 'pageChange'});
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.page = changes.page.currentValue;
      this.setTheme();
    }

    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      if (this.isFromEditor && this.paginator) {
        this.paginator.pageIndex = changes.widget.currentValue.pageNumber - 1;
        this.paginator.length = changes.widget.currentValue.totalRecords;
      }
      this.addShapeClassName();
    }

    if (
      changes.widgetStyleForm !== undefined &&
      !changes.widgetStyleForm.isFirstChange() &&
      changes.widgetStyleForm.currentValue
    ) {
      setTimeout(() => {
        // Set Form Style Values
        if (changes.widgetStyleForm.currentValue.type === 'style') {
          // Title
          this.widget.title = changes.widgetStyleForm.currentValue.title;

          this.widget.widgetIcon =
            changes.widgetStyleForm.currentValue.widgetIcon;
          if (
            this.widget.widgetIcon &&
            typeof this.widget.widgetIcon !== 'string'
          ) {
            this.widget.widgetIcon.position = changes.widgetStyleForm
              .currentValue?.widgetIconPosition
              ? changes.widgetStyleForm.currentValue?.widgetIconPosition
              : 'left';
          }

          // Background Color
          if (
            changes.widgetStyleForm.currentValue.backgroundColor !== '' &&
            changes.widgetStyleForm.currentValue.backgroundColor !== null
          ) {
            this.theme.backgroundColor =
              changes.widgetStyleForm.currentValue.backgroundColor;
          }
          if (!changes.widgetStyleForm.currentValue.backgroundColor) {
            this.theme.backgroundColor = this.pageTheme?.backgroundColor
              ? this.pageTheme.backgroundColor
              : this.reportTheme.backgroundColor;
          }

          // Border Color
          if (
            changes.widgetStyleForm.currentValue.borderColor !== '' &&
            changes.widgetStyleForm.currentValue.borderColor !== null
          ) {
            this.theme.borderColor =
              changes.widgetStyleForm.currentValue.borderColor;
          } else if (!changes.widgetStyleForm.currentValue.borderColor) {
            this.theme.borderColor = this.pageTheme?.borderColor
              ? this.pageTheme.borderColor
              : this.reportTheme.borderColor;
          }

          // Body Color
          if (
            changes.widgetStyleForm.currentValue.bodyColor !== '' &&
            changes.widgetStyleForm.currentValue.bodyColor !== null
          ) {
            this.theme.bodyColor =
              changes.widgetStyleForm.currentValue.bodyColor;
          } else if (!changes.widgetStyleForm.currentValue.bodyColor) {
            this.theme.bodyColor = this.pageTheme?.bodyColor
              ? this.pageTheme.bodyColor
              : this.reportTheme.bodyColor;
          }

          // Title Color
          if (
            changes.widgetStyleForm.currentValue.titleColor !== '' &&
            changes.widgetStyleForm.currentValue.titleColor !== null
          ) {
            this.theme.titleColor =
              changes.widgetStyleForm.currentValue.titleColor;
          } else if (!changes.widgetStyleForm.currentValue.titleColor) {
            this.theme.titleColor = this.pageTheme?.titleColor
              ? this.pageTheme.titleColor
              : this.reportTheme.titleColor;
          }

          // Transparency
          this.theme.transparency =
            changes.widgetStyleForm.currentValue.transparency;

          // Label Bold
          this.theme.labelBold =
            changes.widgetStyleForm.currentValue?.labelBold;

          // Label Italic
          this.theme.labelItalic =
            changes.widgetStyleForm.currentValue?.labelItalic;

          // Label Align
          if (changes.widgetStyleForm.currentValue?.labelAlign) {
            this.theme.labelAlign =
              changes.widgetStyleForm.currentValue?.labelAlign;
          }

          // Schema Indicator
          if (changes.widgetStyleForm.currentValue?.schemaIndicator) {
            this.theme.schemaIndicator =
              changes.widgetStyleForm.currentValue.schemaIndicator;
          }

          // Text Color
          if (
            changes.widgetStyleForm.currentValue?.websiteColor !== '' &&
            changes.widgetStyleForm.currentValue?.websiteColor !== null
          ) {
            this.theme.websiteColor =
              changes.widgetStyleForm.currentValue.websiteColor;
          } else if (!changes.widgetStyleForm.currentValue.websiteColor) {
            this.theme.websiteColor = this.pageTheme?.websiteColor
              ? this.pageTheme.websiteColor
              : this.reportTheme.websiteColor;
          }

          // Font Size
          if (
            changes.widgetStyleForm.currentValue.fontSize.size !== '' &&
            changes.widgetStyleForm.currentValue.fontSize.size !== null
          ) {
            this.theme.fontSize =
              changes.widgetStyleForm.currentValue.fontSize.size;
          } else if (!changes.widgetStyleForm.currentValue.fontSize) {
            this.theme.fontSize = this.pageTheme?.fontSize
              ? this.pageTheme.fontSize
              : this.reportTheme.fontSize;
          }

          // Title Font Size
          if (
            changes.widgetStyleForm.currentValue.titleFontSize.size !== '' &&
            changes.widgetStyleForm.currentValue.titleFontSize.size !== null
          ) {
            this.theme.titleFontSize =
              changes.widgetStyleForm.currentValue.titleFontSize.size;
          } else if (!changes.widgetStyleForm.currentValue.titleFontSize) {
            this.theme.titleFontSize = this.pageTheme?.fontSize
              ? this.pageTheme.fontSize
              : this.reportTheme.fontSize;
          }

          // Alignment
          if (changes.widgetStyleForm.currentValue.alignment) {
            this.widget.alignment =
              changes.widgetStyleForm.currentValue.alignment;
          }

          // KPI Label
          if (
            changes.widgetStyleForm.currentValue.kpiLabel ||
            changes.widgetStyleForm.currentValue.kpiLabel === ''
          ) {
            this.widget.kpiLabel =
              changes.widgetStyleForm.currentValue.kpiLabel;
          }

          // Goal Tracker | current-goal
          if (
            changes.widgetStyleForm.currentValue.resultValueLabel ||
            changes.widgetStyleForm.currentValue.resultValueLabel === ''
          ) {
            this.widget.resultValueLabel =
              changes.widgetStyleForm.currentValue.resultValueLabel;
          }
          if (
            changes.widgetStyleForm.currentValue.goalAmountLabel ||
            changes.widgetStyleForm.currentValue.goalAmountLabel === ''
          ) {
            this.widget.goalAmountLabel =
              changes.widgetStyleForm.currentValue.goalAmountLabel;
          }

          // Goal Tracker | gauge
          this.widget.showPercentage = Boolean(
            changes.widgetStyleForm.currentValue.showPercentage
          );

          // Corners
          if (changes.widgetStyleForm.currentValue?.cornerForm) {
            if (
              this.widget.theme?.allCorners &&
              this.widget.theme?.allCorners >= 0
            ) {
              this.theme.allCorners = this.widget.theme.allCorners;
            }

            if (
              this.widget.theme?.topLeftCorner &&
              this.widget.theme?.topLeftCorner >= 0
            ) {
              this.theme.topLeftCorner = this.widget.theme.topLeftCorner;
            }

            if (
              this.widget.theme?.topRightCorner &&
              this.widget.theme?.topRightCorner >= 0
            ) {
              this.theme.topRightCorner = this.widget.theme.topRightCorner;
            }

            if (
              this.widget.theme?.bottomLeftCorner &&
              this.widget.theme?.bottomLeftCorner >= 0
            ) {
              this.theme.bottomLeftCorner = this.widget.theme.bottomLeftCorner;
            }

            if (
              this.widget.theme?.bottomRightCorner &&
              this.widget.theme?.bottomRightCorner >= 0
            ) {
              this.theme.bottomRightCorner =
                this.widget.theme.bottomRightCorner;
            }

            this.addShapeClassName();
          }
        }
      }, 0);
    }

    if (
      this.widget.widgetType !== 'image' &&
      this.widget.widgetType !== 'branding'
    ) {
      this.updateDynamicDates();
    }
  }

  editWidget(): void {
    const widgetSandbox = {...this.widget};
    const data: EditWidgetConfigData = {
      widget: {...widgetSandbox},
      theme: this.theme,
      style: {
        height: this.widgetCard.nativeElement.offsetHeight + 'px',
        width: this.widgetCard.nativeElement.offsetWidth + 'px',
      },
      page: this.page,
      reportTheme: this.reportTheme,
      reportId: this.reportId,
      pages: this.pages,
    };

    this.editWidgetEmitter.emit(data);
  }

  refreshWidgetAfterEdit(widget: Widget | null): void {
    if (widget !== null && widget !== undefined) {
      const widgetSandbox = {...this.widget};
      if (widget.id === this.widget.id) {
        this.widget = widget;
        this.setTheme();
        if (!this.widget?.isOriginal) {
          this.widgetService.cascadeSaveWidget(this.widget);
        }
        if (!widget) {
          this.widget = widgetSandbox as Widget;
        }
      }
    }
  }

  private setTheme(): void {
    const colorKeys = [
      'titleColor',
      'borderColor',
      'bodyColor',
      'tableHeaderBgColor',
      'tableHeaderTextColor',
      'tableHeaderBorderColor',
      'tableRowBorderColor',
      'tableRowBgColor',
    ];
    if (!this.widget.theme || Array.isArray(this.widget.theme)) {
      this.widget.theme = {};
    }

    if (this.page) {
      this.pageTheme = this.page.theme as ReportTheme;
    }

    for (const key in this.widget?.theme) {
      const themeKeyValue = this.widget?.theme[key];
      if (typeof this.widget.theme[key] === 'string') {
        if ((this.widget.theme[key] as string).trim().length === 0) {
          delete this.widget.theme[key];
        }

        if (
          (themeKeyValue as string).trim().length !== 0 &&
          colorKeys.includes(key) &&
          this.colorService.getColorType(themeKeyValue) === null
        ) {
          delete this.widget.theme[key];
        }
      }
    }

    Object.assign(
      this.theme,
      this.reportTheme,
      this.pageTheme,
      this.widget.theme
    );
  }

  /**
   * Set dynamic dates in widget titles.
   */
  private updateDynamicDates(): void {
    let replaceText: string;
    const regexBraces = /\{{(.*?)\}}/g;
    const dateList: Array<string> = [];
    const dynamicDates: Array<DynamicDate> =
      this.helperService.getDynamicDates();
    replaceText =
      this.widget.widgetType === 'scorecard'
        ? this.widget?.kpiLabel || this.widget?.kpiData?.name || ''
        : this.widget?.title || '';
    const whileTrue = true;

    while (whileTrue) {
      const found = regexBraces.exec(replaceText);
      if (!found) {
        break;
      }
      dateList.push(`${found[1]}`);
    }

    const currentDateRange: ReportDateRange = (this.widget?.dateRange ||
      this.page?.dateRange) as ReportDateRange;

    dateList.forEach((value: string): void => {
      if (value?.length > 0) {
        for (const dynamicDate of dynamicDates) {
          if (dynamicDate.combinations.indexOf(value.toLowerCase()) >= 0) {
            let dateRange: DateRange | null = null;

            if (value.toLowerCase().startsWith('current_')) {
              dateRange = currentDateRange?.current;
            } else {
              if (
                value.toLowerCase().startsWith('previous_') &&
                currentDateRange?.previous
              ) {
                dateRange = currentDateRange?.previous;
              }
            }

            if (
              replaceText
                ?.toLowerCase()
                ?.includes(`{{${value.toLowerCase()}}}`) &&
              dateRange
            ) {
              let formattedValue = '';
              if (value.toLowerCase().endsWith('_start')) {
                formattedValue = `${formatDate(
                  dateRange?.start as string,
                  dynamicDate.pipeFormat,
                  this.userLocale
                )}`;
              }
              if (value.toLowerCase().endsWith('_end')) {
                formattedValue = `${formatDate(
                  dateRange?.end as string,
                  dynamicDate.pipeFormat,
                  this.userLocale
                )}`;
              }
              if (value.toLowerCase().endsWith('_both')) {
                formattedValue = `${formatDate(
                  dateRange?.start as string,
                  dynamicDate.pipeFormat,
                  this.userLocale
                )} - ${formatDate(
                  dateRange?.end as string,
                  dynamicDate.pipeFormat,
                  this.userLocale
                )}`;
              }
              replaceText = replaceText.replace(
                new RegExp(`{{${value}}}`, 'ig'),
                formattedValue
              );
            }
          }
        }
      }
    });

    if (this.widget.widgetType === 'scorecard') {
      this.widget.dynamicKpiLabel = replaceText;
    } else {
      this.widget.dynamicTitle = replaceText;
    }
  }

  private addShapeClassName(): void {
    const classList: Array<string> = [
      'triangle-up',
      'trapezoid-up',
      'pentagon-up',
      'rhombus',
      'left-arrow',
      'right-arrow',
      'left-point',
      'right-point',
      'left-chevron',
      'right-chevron',
      'star',
      'message',
      'circle',
    ];

    if (this.widgetCard?.nativeElement && this.widget.theme?.shapeClass) {
      if (this.widget?.shapeType === 'shapes') {
        classList.forEach((className: string): void => {
          if (className !== this.widget.theme.shapeClass) {
            this.widgetCard.nativeElement.className =
              this.widgetCard.nativeElement.className.replace(
                ` ${className}`,
                ''
              );
          }
        });

        if (
          !this.widgetCard.nativeElement.className.includes(
            this.widget.theme.shapeClass
          )
        ) {
          this.widgetCard.nativeElement.className += ` ${this.widget.theme.shapeClass}`;
        }
      }

      if (this.widget?.shapeType === 'round') {
        classList.forEach((className) => {
          this.widgetCard.nativeElement.className =
            this.widgetCard.nativeElement.className.replace(
              ` ${className}`,
              ''
            );
        });
      }

      if (!this.widgetCard.nativeElement?.classList?.contains('widget-card')) {
        this.widgetCard.nativeElement.classList.add('widget-card');
      }
    }
  }

  onMoveWidget(event): void {
    if (this.isEditMode && !this.isFromEditor) {
      // this.showActions.set(false);
      this.actionsActive.emit({widgetId: this.widget?.id});
    }
    this.positionChanged.emit(event);
  }

  onWidgetSortChanged(event: WidgetSort): void {
    this.isLoading = true;
    this.reportService.setChangeValue = {
      hasChanges: !(this.isMobile || this.isExternalUser),
    };
    const currenSortDirection = this.widget.sortDirection;
    if (event.type === 'sortChange') {
      this.widget.sortDirection =
        currenSortDirection === 'asc' ? 'desc' : 'asc';
    }

    const payload = JSON.parse(JSON.stringify(this.page));
    payload.widgets = [event.widget];
    payload.widgetPreview = true;

    const previewSub = (): Observable<ReportPage | CustomError> => {
      if (this.sharedAuth) {
        return this.sharedService.previewData(
          this.reportId,
          this.page.id,
          payload,
          this.sharedAuth
        );
      } else {
        return this.widgetService.previewData(
          this.reportId,
          this.page.id,
          payload
        );
      }
    };

    previewSub().subscribe({
      next: (res) => {
        const response = res as ReportPage;
        const oldElements: any[] | undefined = this.widget.tableData?.rows;
        this.widget = response.widgets[0];
        if (this.widget.widgetType === 'table' && this.isMobile) {
          if (this.widget.tableData && oldElements) {
            this.widget.tableData.rows = [
              ...oldElements,
              ...this.widget.tableData.rows,
            ];
          }
        }
        this.isLoading = false;
        this.widgetSortChanged.emit(this.widget);
      },
      error: (err: CustomError): void => {
        this.notificationService.error(err?.message || '', 5000);
        this.isLoading = false;
      },
    });
  }

  /**
   * Used to change page when limit is reached in card carousel.
   */
  changePageOnLimitReached(): void {
    this.widget.pageNumber =
      this.widget.pageNumber && this.widget.pageNumber + 1;
    this.onWidgetSortChanged({widget: this.widget, type: 'pageChange'});
  }

  /**
   * Used to control widget action popover.
   * @param actionType
   */
  onWidgetActions(actionType: string): void {
    if (this.isEditMode && !this.isFromEditor) {
      switch (actionType) {
        case 'clearTimer':
          clearTimeout(this.timer);
          this.actionsActive.emit({widgetId: this.widget?.id});
          break;
        case 'show':
          if (!this.isMovingItem) {
            clearTimeout(this.timer);
            this.showActions.set(true);
            this.actionsActive.emit({widgetId: this.widget?.id});
          }
          break;
        case 'close':
          this.timer = setTimeout((): void => {
            this.showActions.set(false);
          }, 100);
          this.actionsActive.emit({widgetId: ''});
          break;
        case 'move':
          // this.showActions.set(false);
          this.actionsActive.emit({widgetId: this.widget?.id});
          break;
        case 'endMove':
          // this.showActions.set(false);
          setTimeout((): void => {
            this.showActions.set(true);
            this.actionsActive.emit({widgetId: this.widget?.id});
          }, 100);
          break;
      }
    }
  }

  onContextMenu(actionData: IWidgetContextMenu): void {
    const action = actionData.action;
    this.showActions.set(false);
    switch (action) {
      case 'edit':
        this.editWidget();
        break;
      case 'editTitle':
        this.widgetService.setEditTitleWidgetTitle(
          true,
          actionData?.widget?.id
        );
        break;
      case 'moveTop':
        this.onMoveWidget({
          widget: actionData?.widget,
          position: 'top',
        });
        break;
      case 'moveBottom':
        this.onMoveWidget({
          widget: actionData?.widget,
          position: 'bottom',
        });
        break;
      case 'duplicate':
        this.widgetService.cascadeDuplicateWidget(actionData?.widget);
        break;
      case 'delete':
        this.widgetService.cascadeDeleteWidget({
          widgetId: actionData?.widget?.id,
          pageId: actionData?.widget?.pageId || this.page?.id,
        });
        break;
      case 'library':
        this.saveAsTemplate(actionData.widget);
        break;
      case 'copyStyle':
        this.onCopyWidgetStyle();
        break;
      case 'pasteStyle':
        this.onPasteWidgetStyle().then();
        break;
    }
  }

  saveAsTemplate(widget: Widget): void {
    const config: MatDialogConfig = {
      data: {
        widget: widget,
        height: this.widgetCard.nativeElement.offsetHeight + 'px',
        width: this.widgetCard.nativeElement.offsetWidth + 'px',
        type: 'widget',
      },
      width: '43vw',
      height: '58vh',
      autoFocus: false,
      closeOnNavigation: true,
    };

    this.dialogService.openDialog(SaveAsTemplateDialogComponent, config);
  }

  onCloseContextMenu(): void {
    this.widgetService.onContextMenu(null);
    this.onWidgetActions('close');
  }

  onCopyWidgetStyle(): void {
    const themeCopy: ReportTheme = this.widget?.theme || {};
    if (Object.keys(themeCopy)?.length > 0) {
      themeCopy.widgetType = this.widget?.widgetType;
      if (this.widget?.widgetType === 'chart') {
        themeCopy.chartType = this.widget?.chartData?.chartType; // Set chart type
      }
      const properties: string[] = propertiesToCopy();
      themeCopy.extraStyles = properties
        .filter((prop) => this.widget?.[prop])
        .map((prop) => ({[prop]: this.widget[prop]}));
      const themeCopyString = JSON.stringify(themeCopy);
      this.clipboard.copy(themeCopyString);
    }
  }

  async onPasteWidgetStyle(): Promise<void> {
    await navigator.permissions
      .query({name: 'clipboard-read' as PermissionName})
      .then((result): void => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard
            .readText()
            .then((clipboardContent: string): void => {
              if (clipboardContent) {
                try {
                  const themeCopy: ReportTheme = JSON.parse(clipboardContent);
                  if (themeCopy?.widgetType === this.widget.widgetType) {
                    if (themeCopy?.widgetType !== 'chart') {
                      // Normal Widget
                      this.setPastedTheme(themeCopy);
                    } else if (
                      themeCopy?.widgetType === 'chart' &&
                      themeCopy?.chartType === this.widget.chartData?.chartType
                    ) {
                      // Same chart type
                      this.setPastedTheme(themeCopy);
                    } else {
                      this.notificationService.warning(
                        this.translate.instant('context_menu.type_mismatch'),
                        5000
                      );
                    }
                  } else {
                    this.notificationService.warning(
                      this.translate.instant('context_menu.type_mismatch'),
                      5000
                    );
                  }
                } catch {
                  console.warn('Failed to parse theme from clipboard.');
                }
              }
            })
            .catch((): void => {
              this.notificationService.error(
                this.translate.instant('context_menu.failed_to_read_clipboard'),
                5000
              );
            });
        } else {
          this.notificationService.warning(
            this.translate.instant('context_menu.clipboard_not_access'),
            5000
          );
        }
      })
      .catch((): void => {
        console.error(
          this.translate.instant('context_menu.error_read_clipboard')
        );
      });
  }

  private setPastedTheme(themeCopy: ReportTheme): void {
    const themeSandbox = {...themeCopy};
    // Set styles to level widget
    if (themeSandbox.extraStyles && themeSandbox?.extraStyles?.length > 0) {
      for (const style of themeSandbox.extraStyles) {
        for (const [key, value] of Object.entries(style)) {
          this.widget[key] = value;
        }
      }
    }
    // Set styles to level theme
    delete themeCopy?.widgetType;
    delete themeCopy?.chartType;
    delete themeCopy?.extraStyles;
    this.widget.theme = {...themeCopy};
    this.setTheme();
    this.addShapeClassName();
    this.reportService.setChangeValue = {hasChanges: true};
    if (
      themeSandbox?.widgetType === 'chart' ||
      themeSandbox?.widgetType === 'table' ||
      themeSandbox?.widgetType === 'gallery' ||
      themeSandbox?.widgetType === 'timeline'
    ) {
      this.previewWidgetStyle().then();
    }
  }

  private async previewWidgetStyle(): Promise<void> {
    const payload: any = JSON.parse(JSON.stringify(this.page));
    payload.widgets = [this.widget];
    const preview$ = this.widgetService.previewData(
      this.reportId,
      this.page.id,
      payload
    );
    await lastValueFrom(preview$).then((res): void => {
      if (this.widget.isDuplicate) {
        this.widget = res.widgets[0];
        this.widget.isDuplicate = true;
      } else {
        this.widget = res.widgets[0];
      }
    });
  }

  onOpenCustomContextMenu(event: MouseEvent): void {
    this.openContextMenu(event);
  }

  onShowContextMenu(event: MouseEvent): void {
    event.preventDefault();
    this.openContextMenu(event);
  }

  private openContextMenu(event: MouseEvent): void {
    if (this.contextMenuTrigger) {
      this.contextMenuTrigger.close();
      const coordinates = {x: event.clientX, y: event.clientY};
      this.contextMenuTrigger.open(coordinates);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
