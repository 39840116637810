import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ReportTheme} from '../../shared/models';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  private readonly apiURL = environment.apiUrl;
  private baseURL = `${this.apiURL}branding/`;

  root = document.documentElement;
  bodyStyles = window.getComputedStyle(document.body);
  primaryColorBrand = this.bodyStyles.getPropertyValue('--brand-primary');
  secondaryColorBrand = this.bodyStyles.getPropertyValue('--brand-secondary');

  private sharedThemeSubject = new BehaviorSubject<ReportTheme | null>({});

  branding = {
    primaryColor: this.primaryColorBrand,
    secondaryColor: this.secondaryColorBrand,
  };

  public reloadGallerySubject = new Subject();

  constructor(private http: HttpClient) {}

  getBranding(): {primaryColor: string; secondaryColor: string} {
    return this.branding;
  }

  /**
   * Sets global branding for organization
   * @param primary Hex color code string
   * @param secondary Hex color code string
   */
  setBranding(primary: string, secondary: string): void {
    this.root.style.setProperty('--brand-primary', primary);
    this.root.style.setProperty('--brand-secondary', secondary);

    this.primaryColorBrand =
      this.bodyStyles.getPropertyValue('--brand-primary');
    this.secondaryColorBrand =
      this.bodyStyles.getPropertyValue('--brand-secondary');

    this.branding = {
      primaryColor: this.primaryColorBrand,
      secondaryColor: this.secondaryColorBrand,
    };
  }

  setColors(color): void {
    const root = document.documentElement;
    const primaryColor = color.trim();

    const primaryLightHSL = this.toHslRgb(primaryColor, 60).hsl;
    const primaryLighterHSL = this.toHslRgb(primaryColor, 80).hsl;
    const primaryLightestHSL = this.toHslRgb(primaryColor, 90).hsl;

    const primaryDarkHSL = this.toHslRgb(primaryColor, 40).hsl;
    const primaryDarkerHSL = this.toHslRgb(primaryColor, 30).hsl;
    const primaryDarkestHSL = this.toHslRgb(primaryColor, 20).hsl;

    const primaryColorRGB = this.toHslRgb(primaryColor, 60).rgbObject;
    const primaryRGB =
      primaryColorRGB[0] + ',' + primaryColorRGB[1] + ',' + primaryColorRGB[2];

    root.style.setProperty('--primary-color', primaryColor);
    root.style.setProperty('--primary-light', primaryLightHSL);
    root.style.setProperty('--primary-lighter', primaryLighterHSL);
    root.style.setProperty('--primary-lightest', primaryLightestHSL);
    root.style.setProperty('--primary-dark', primaryDarkHSL);
    root.style.setProperty('--primary-darker', primaryDarkerHSL);
    root.style.setProperty('--primary-darkest', primaryDarkestHSL);
    root.style.setProperty('--primaryRGB', primaryRGB);
  }

  toHslRgb(
    hex,
    lightness
  ): {rgb: string; hsl: string; originalHSL: string; rgbObject: number[]} {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    let r = result ? parseInt(result[1], 16) : 0;
    let g = result ? parseInt(result[2], 16) : 0;
    let b = result ? parseInt(result[3], 16) : 0;

    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = (max + min) / 2;
    let s = (max + min) / 2;
    let l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    return {
      rgb: `${r * 255}, ${g * 255}, ${b * 255}`,
      hsl: `hsl(${h}, ${s}%, ${lightness}%)`,
      originalHSL: `hsl(${h}, ${s}%, ${l}%)`,
      rgbObject: [r * 255, g * 255, b * 255],
    };
  }

  public getReportThemes(payload?: any): Observable<any> {
    return this.http.get<ReportTheme[]>(`${this.baseURL}report-theme/`, {
      params: payload,
      responseType: 'json',
    });
  }

  public saveReportTheme(payload: any): Observable<ReportTheme> {
    return this.http.post<any>(`${this.baseURL}report-theme/`, payload, {
      responseType: 'json',
    });
  }

  public updateReportTheme(payload: any, id: string): Observable<ReportTheme> {
    return this.http.post<any>(`${this.baseURL}report-theme/${id}/`, payload, {
      responseType: 'json',
    });
  }

  public deleteReportTheme(id: string): Observable<ReportTheme> {
    return this.http.delete<any>(`${this.baseURL}report-theme/${id}/`, {
      responseType: 'json',
    });
  }

  public lockOrUnLockEditTheme(
    id: string,
    payload: {isReadOnly: boolean}
  ): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}report-theme/unlock/${id}`,
      payload
    );
  }

  public cloneTheme(id: string): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}report-theme/duplicate/${id}`,
      null
    );
  }

  // sharing theme
  shareReportTheme(theme: ReportTheme | null): void {
    const cTheme = JSON.stringify(theme);
    sessionStorage.setItem('sharedTheme', cTheme);
    return this.sharedThemeSubject.next(theme);
  }

  getReportTheme(): Observable<ReportTheme | null> {
    if (sessionStorage.getItem('sharedTheme')) {
      const cTheme = JSON.parse(
        sessionStorage.getItem('sharedTheme') as string
      );
      this.shareReportTheme(cTheme);
    }
    return this.sharedThemeSubject.asObservable();
  }

  clearSharedReportTheme(): void {
    sessionStorage.removeItem('sharedTheme');
    this.shareReportTheme(null);
  }
}
