<div class="preview-container">
  <div class="d-flex justify-content-center controls p-top">
    <a
      (click)="handlePageActive(1)"
      [ngClass]="{'c-active': pageActive === 1}"
      class="circle"
    ></a>
    <a
      (click)="handlePageActive(2)"
      [ngClass]="{'c-active': pageActive === 2}"
      class="circle"
    ></a>
  </div>

  @if (pageActive === 1) {
    <div
      class="background-mockup d-flex flex-column"
      [style.border-color]="
        theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
      "
      [style.background-color]="
        theme?.pageColor ? theme?.pageColor : reportTheme?.pageColor
      "
      [style.background-image]="'url(' + bckImgSrcPivot + ')'"
      [ngClass]="{'bck-img': bckImgSrcPivot}"
    >
      <div
        class="page-mockup page-header mb-3"
        [style.background-color]="
          theme?.widgetBackgroundColor
            ? theme?.widgetBackgroundColor
            : reportTheme?.widgetBackgroundColor
        "
        [style.border-color]="
          theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
        "
      >
        @if (imgSrc) {
          <img [src]="imgSrc" height="18px" alt="" />
        } @else {
          <div class="img-placeholder">
            <i class="fa-solid fa-image"></i>&nbsp;
            <span>{{ "theme.preview.logo" | translate }}</span>
          </div>
        }
        <span
          class="branding-text"
          [style.color]="
            theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
          "
          >{{ theme?.brandingText }}</span
        >
      </div>
      <div class="row h-100">
        <div class="col h-100 pr-0 d-flex flex-column gap-2">
          <div
            class="page-mockup w-100"
            [style.background-color]="
              theme?.widgetBackgroundColor
                ? theme?.widgetBackgroundColor
                : reportTheme?.widgetBackgroundColor
            "
            [style.border-color]="
              theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
            "
          >
            <h5
              [style.color]="
                theme?.titleColor ? theme?.titleColor : reportTheme?.titleColor
              "
            >
              {{ "theme.preview.header" | translate }}
            </h5>
          </div>
          <div
            class="page-mockup text-container d-flex flex-column"
            [style.background-color]="
              theme?.widgetBackgroundColor
                ? theme?.widgetBackgroundColor
                : reportTheme?.widgetBackgroundColor
            "
            [style.border-color]="
              theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
            "
          >
            <p
              [style.color]="
                theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
              "
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </div>
        </div>
        <div class="col h-100">
          <div
            class="scorecard-mockup h-20 mb-2"
            [style.background-color]="
              theme?.widgetBackgroundColor
                ? theme?.widgetBackgroundColor
                : reportTheme?.widgetBackgroundColor
            "
            [style.border-color]="
              theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
            "
          >
            <span
              class="scorecard-label-mockup"
              [style.background-color]="
                theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
              "
            ></span>
            <span
              class="scorecard-value-mockup"
              [style.background-color]="
                theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
              "
            ></span>
            <span
              class="scorecard-compare-mockup {{
                'indicator-' + theme.schemaIndicator
              }}"
            >
              <span class="scorecard-compare-neutro scorecard-compare-item">
                <span></span>
              </span>
              <span class="scorecard-compare-divider divider-succcess">|</span>
              <span class="scorecard-compare-success scorecard-compare-item">
                <span></span>
              </span>
            </span>
          </div>
          <div
            class="scorecard-mockup h-20 mb-2"
            [style.background-color]="
              theme?.widgetBackgroundColor
                ? theme?.widgetBackgroundColor
                : reportTheme?.widgetBackgroundColor
            "
            [style.border-color]="
              theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
            "
          >
            <span
              class="scorecard-label-mockup"
              [style.background-color]="
                theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
              "
            ></span>
            <span
              class="scorecard-value-mockup"
              [style.background-color]="
                theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
              "
            ></span>
            <span
              class="scorecard-compare-mockup {{
                'indicator-' + theme.schemaIndicator
              }}"
            >
              <span class="scorecard-compare-neutro scorecard-compare-item">
                <span></span>
              </span>
              <span class="scorecard-compare-divider divider-error">|</span>
              <span class="scorecard-compare-error scorecard-compare-item">
                <span></span>
              </span>
            </span>
          </div>
          <div
            class="page-mockup h-54 d-flex justify-content-center align-items-center"
            [style.background-color]="
              theme?.widgetBackgroundColor
                ? theme?.widgetBackgroundColor
                : reportTheme?.widgetBackgroundColor
            "
            [style.border-color]="
              theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
            "
          >
            <div class="pie">
              <div class="slice slice_1">
                <div [style.background-color]="(theme?.colorPalette)![0]"></div>
              </div>
              <div class="slice slice_2">
                <div [style.background-color]="(theme?.colorPalette)![1]"></div>
              </div>
              <div class="slice slice_3">
                <div [style.background-color]="(theme?.colorPalette)![2]"></div>
              </div>
              <div class="slice slice_4">
                <div [style.background-color]="(theme?.colorPalette)![3]"></div>
              </div>
              <div class="slice slice_5">
                <div [style.background-color]="(theme?.colorPalette)![4]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  @if (pageActive === 2) {
    <div
      class="background-mockup d-flex flex-column gap-2"
      [style.border-color]="
        theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
      "
      [style.background-color]="
        theme?.pageColor ? theme?.pageColor : reportTheme?.pageColor
      "
      [style.background-image]="'url(' + bckImgSrcPivot + ')'"
      [ngClass]="{'bck-img': bckImgSrcPivot}"
    >
      <div
        class="page-mockup page-header"
        [style.background-color]="
          theme?.widgetBackgroundColor
            ? theme?.widgetBackgroundColor
            : reportTheme?.widgetBackgroundColor
        "
        [style.border-color]="
          theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
        "
      >
        @if (imgSrc) {
          <img [src]="imgSrc" height="18px" alt="" />
        } @else {
          <div class="img-placeholder">
            <i class="fa-solid fa-image"></i>&nbsp;
            <span>{{ "theme.preview.logo" | translate }}</span>
          </div>
        }
        <span
          class="branding-text"
          [style.color]="
            theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
          "
          >{{ theme?.brandingText }}</span
        >
      </div>
      <div class="row">
        <div class="col">
          <div
            class="page-mockup w-100"
            [style.background-color]="
              theme?.widgetBackgroundColor
                ? theme?.widgetBackgroundColor
                : reportTheme?.widgetBackgroundColor
            "
            [style.border-color]="
              theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
            "
          >
            <h5
              [style.color]="
                theme?.titleColor ? theme?.titleColor : reportTheme?.titleColor
              "
            >
              {{ "theme.preview.header" | translate }}
            </h5>
          </div>
        </div>
      </div>
      <div class="row h-100">
        <div class="col h-100 d-flex flex-column">
          <div
            class="page-mockup text-container flex-grow-1"
            [style.background-color]="
              theme?.widgetBackgroundColor
                ? theme?.widgetBackgroundColor
                : reportTheme?.widgetBackgroundColor
            "
            [style.border-color]="
              theme?.borderColor ? theme?.borderColor : reportTheme?.borderColor
            "
          >
            <div
              class="h-25 d-flex justify-content-around align-items-center table-mockup-header"
              [ngStyle]="{
                'background-color': theme?.tableHeaderBgColor
                  ? theme?.tableHeaderBgColor
                  : theme?.pageColor
                    ? theme.pageColor
                    : reportTheme?.pageColor,
                'border-color': theme?.tableHeaderBorderColor
                  ? theme?.tableHeaderBorderColor
                  : reportTheme?.pageColor,
                'color': theme?.tableHeaderTextColor
                  ? theme?.tableHeaderTextColor
                  : theme?.bodyColor || reportTheme?.bodyColor,
              }"
            >
              <div class="font-weight-bold">----</div>
              <div class="font-weight-bold">----</div>
              <div class="font-weight-bold">----</div>
              <div class="font-weight-bold">----</div>
            </div>
            <div
              class="table-mockup-row d-flex justify-content-around"
              [ngStyle]="{
                'background-color': theme?.stripedRows
                  ? stripeColor
                  : theme?.tableRowBgColor,
                'border-color': theme?.tableRowBorderColor
                  ? theme?.tableRowBorderColor
                  : theme?.pageColor
                    ? theme.pageColor
                    : reportTheme?.pageColor
              }"
            >
              <span
                [style.color]="
                  theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
                "
                class="row-item text-center"
                >------</span
              >
              <span
                [style.color]="
                  theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
                "
                class="row-item text-center"
                >------</span
              >
              <span
                [style.color]="
                  theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
                "
                class="row-item text-center"
                >------</span
              >
              <span
                class="table-compare-mockup {{
                  'indicator-' + theme.schemaIndicator
                }}"
              >
                <span class="table-compare-neutro table-compare-item">
                  <span
                    [style.backgroundColor]="
                      theme?.bodyColor
                        ? theme?.bodyColor
                        : reportTheme?.bodyColor
                    "
                  ></span>
                </span>
                <span class="table-compare-error table-compare-item">
                  <span></span>
                </span>
              </span>
            </div>
            <div
              class="table-mockup-row d-flex justify-content-around"
              [ngStyle]="{
                'background-color': theme?.tableRowBgColor
                  ? theme?.tableRowBgColor
                  : reportTheme?.widgetBackgroundColor,
                'border-color': theme?.tableRowBorderColor
                  ? theme?.tableRowBorderColor
                  : theme?.pageColor
                    ? theme.pageColor
                    : reportTheme?.pageColor
              }"
            >
              <span
                [style.color]="
                  theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
                "
                class="row-item text-center"
                >------</span
              >
              <span
                [style.color]="
                  theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
                "
                class="row-item text-center"
                >------</span
              >
              <span
                [style.color]="
                  theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
                "
                class="row-item text-center"
                >------</span
              >
              <span
                class="table-compare-mockup {{
                  'indicator-' + theme?.schemaIndicator
                }}"
              >
                <span class="table-compare-neutro table-compare-item">
                  <span
                    [style.backgroundColor]="
                      theme?.bodyColor
                        ? theme?.bodyColor
                        : reportTheme?.bodyColor
                    "
                  ></span>
                </span>
                <span class="table-compare-error table-compare-item">
                  <span></span>
                </span>
              </span>
            </div>
            <div
              class="table-mockup-row d-flex justify-content-around"
              [ngStyle]="{
                'background-color': theme?.stripedRows
                  ? stripeColor
                  : theme?.tableRowBgColor,
                'border-color': theme?.tableRowBorderColor
                  ? theme?.tableRowBorderColor
                  : theme?.pageColor
                    ? theme.pageColor
                    : reportTheme?.pageColor
              }"
            >
              <span
                [style.color]="
                  theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
                "
                class="row-item text-center"
                >------</span
              >
              <span
                [style.color]="
                  theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
                "
                class="row-item text-center"
                >------</span
              >
              <span
                [style.color]="
                  theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
                "
                class="row-item text-center"
                >------</span
              >
              <span
                class="table-compare-mockup {{
                  'indicator-' + theme.schemaIndicator
                }}"
              >
                <span class="table-compare-neutro table-compare-item">
                  <span
                    [style.backgroundColor]="
                      theme?.bodyColor
                        ? theme?.bodyColor
                        : reportTheme?.bodyColor
                    "
                  ></span>
                </span>
                <span class="table-compare-success table-compare-item">
                  <span></span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  <div class="d-flex justify-content-center controls p-bottom">
    <a
      (click)="handlePageActive(1)"
      [ngClass]="{'c-active': pageActive === 1}"
      class="circle"
    ></a>
    <a
      (click)="handlePageActive(2)"
      [ngClass]="{'c-active': pageActive === 2}"
      class="circle"
    ></a>
  </div>
</div>
