import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {WidgetCategory} from '../../../models';
import {ThemeService} from 'src/app/core/services';
import {NgClass} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-widget-toolbar',
  templateUrl: './widget-toolbar.component.html',
  styleUrls: ['./widget-toolbar.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class WidgetToolbarComponent implements OnInit {
  // Inputs / Outputs
  @Output() widgetSelection: EventEmitter<string> = new EventEmitter<string>();

  public isDark: boolean;

  widgetCategories: Array<WidgetCategory> = [
    {
      translation: 'reporting.reportToolbar.widget_common_title',
      types: [
        {
          icon: 'fa-regular fa-table',
          type: 'table',
          translation: 'reporting.reportToolbar.widget_type_table',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-circle-1',
          type: 'scorecard',
          translation: 'reporting.reportToolbar.widget_type_scorecard',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-image',
          type: 'image',
          translation: 'reporting.reportToolbar.widget_type_image',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-heading',
          type: 'title',
          translation: 'reporting.reportToolbar.widget_type_title',
          state: 'stable',
        },
        // {
        //   icon: 'fa-regular fa-text',
        //   type: 'text',
        //   translation: 'reporting.reportToolbar.widget_type_richText',
        //   state: 'stable'
        // },
        {
          icon: 'fa-regular fa-file-lines',
          type: 'dynamictext',
          translation: 'reporting.reportToolbar.widget_type_dynamicText',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-diagram-cells',
          type: 'branding',
          translation: 'reporting.reportToolbar.widget_type_branding',
          state: 'stable',
        },
      ],
    },
    {
      translation: 'reporting.reportToolbar.widget_chart_title',
      types: [
        {
          icon: 'fa-regular fa-chart-pie',
          type: 'pie2d',
          translation: 'reporting.reportToolbar.widget_type_pie',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-circle-o',
          type: 'doughnut2d',
          translation: 'reporting.reportToolbar.widget_type_doughnut',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-chart-pyramid fa-rotate-180',
          type: 'funnel',
          translation: 'reporting.reportToolbar.widget_type_funnel',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-chart-column',
          type: 'mscolumn2d',
          translation: 'reporting.reportToolbar.widget_type_msColumn',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-chart-bar',
          type: 'msbar2d',
          translation: 'reporting.reportToolbar.widget_type_msBar',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-chart-mixed',
          type: 'mscombidy2d',
          translation: 'reporting.reportToolbar.widget_type_msCombination',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-chart-line',
          type: 'multiaxisline',
          translation: 'reporting.reportToolbar.widget_type_multiAxisLine',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-area-chart',
          type: 'msarea',
          translation: 'reporting.reportToolbar.widget_type_area',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-bars-progress fa-rotate-90',
          type: 'stackedcolumn2d',
          translation: 'reporting.reportToolbar.widget_type_stackedColumn',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-bars-progress',
          type: 'stackedbar2d',
          translation: 'reporting.reportToolbar.widget_type_stackedBar',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-chart-simple-horizontal',
          type: 'biggestchanges',
          translation: 'reporting.reportToolbar.widget_type_biggestChanges',
          state: 'stable',
        },
      ],
    },
    {
      translation: 'reporting.reportToolbar.widget_goal_tracker',
      types: [
        {
          icon: 'fa-light fa-gauge',
          type: 'gauge',
          translation: 'reporting.reportToolbar.widget_type_gauge',
          state: 'beta',
        },
        {
          icon: 'fak fa-doughnut-current-vs-goal',
          type: 'current-goal',
          translation: 'reporting.reportToolbar.widget_type_current_goal',
          state: 'beta',
        },
        {
          icon: 'fak fa-chart-timeline',
          type: 'timeline',
          translation: 'reporting.reportToolbar.widget_type_timeline',
          state: 'beta',
        },
      ],
    },
    {
      translation: 'reporting.reportToolbar.widget_special_title',
      types: [
        {
          icon: 'fa-regular fa-gallery-thumbnails',
          type: 'gallery',
          translation: 'reporting.reportToolbar.widget_type_gallery',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-folders',
          type: 'widgetTemplate',
          translation: 'reporting.reportToolbar.widget_type_fromLibrary',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-chart-tree-map',
          type: 'container',
          translation: 'reporting.reportToolbar.widget_type_container',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-ranking-star',
          type: 'ranking',
          translation: 'reporting.reportToolbar.widget_type_keywords',
          state: 'stable',
        },
        {
          icon: 'fa-regular fa-earth-americas',
          type: 'geoHeatMap',
          translation: 'reporting.reportToolbar.widget_type_geo_heat_map',
          state: 'beta',
        },
      ],
    },
  ];

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    this.themeService.theme$.subscribe((res: string) => {
      this.isDark = res === 'dark';
    });
  }

  onWidgetSelect(type: string): void {
    this.widgetSelection.emit(type);
  }
}
