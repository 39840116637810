import {
  AfterViewInit,
  Component,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {
  DisplayGrid,
  GridsterComponent,
  GridsterConfig,
  GridsterItem,
  GridType,
  GridsterItemComponent,
} from 'angular-gridster2';

import {WidgetService} from 'src/app/core/services/widget.service';
import {NotificationService, ReportService} from '../../../core/services';
import {NgClass, NgStyle} from '@angular/common';
import {WidgetComponent} from '../widgets/widget/widget.component';
import {ReportTheme} from '../../models/report/report-theme.model';
import {ReportPage} from '../../models/report/report.model';
import {
  EditWidgetConfigData,
  Widget,
  WidgetAction,
  WidgetDelete,
  WidgetPosition,
} from '../../models/widget/widget.model';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {WidgetEditorComponent} from '../widgets/widget-editor/widget-editor.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.scss'],
  standalone: true,
  imports: [
    GridsterComponent,
    NgClass,
    NgStyle,
    GridsterItemComponent,
    WidgetComponent,
  ],
})
export class ReportGridComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  // services
  bottomSheet = inject(MatBottomSheet);
  router = inject(Router);

  // Inputs / Outputs
  @Input() orientation: string;
  @Input() theme: ReportTheme;
  @Input() bckImageUrl: string | null;
  @Input() isEditMode: boolean;
  @Input() page: ReportPage;
  @Input() pages: Array<ReportPage>;
  @Input() reportId: string;
  @Input() isLoading: boolean;
  @Input() isPrint: boolean;

  @ViewChild('gridster', {static: false}) grid: GridsterComponent;

  // State Variables
  private initialized = false;
  isEmpty = false;
  isMoving = false;

  // Properties
  subs: Subscription = new Subscription();
  options: GridsterConfig;
  height: number;
  width: number;
  canvasRatio = '16:9';
  styleSheet;
  gridsterItems: Array<GridsterItem> = [];
  windowWidth: number = window.screen.width;
  isMobile = false;
  widgetActive: WidgetAction;
  currentMove: GridsterItem;
  moveSubject = new Subject();

  constructor(
    private notificationService: NotificationService,
    private widgetService: WidgetService,
    private reportService: ReportService,
    private translate: TranslateService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.windowWidth = event.target.innerWidth;
  }
  ngOnInit(): void {
    if (this.orientation === 'vertical') {
      this.canvasRatio = '9:16';
      this.width = 18;
      this.height = 32;
    } else {
      this.canvasRatio = '16:9';
      this.width = 32;
      this.height = 18;
    }

    this.options = {
      margin: 5,
      outerMargin: true,
      outerMarginTop: 20,
      outerMarginRight: 20,
      outerMarginBottom: 20,
      outerMarginLeft: 20,
      gridType: GridType.Fit,
      fixedColWidth: 250,
      fixedRowHeight: 50,
      minCols: this.width,
      maxCols: this.width,
      minRows: this.height,
      maxRows: this.height,
      defaultItemCols: 1,
      defaultItemRows: 1,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: this.isEditMode,
      },
      resizable: {
        enabled: this.isEditMode,
      },
      swap: true,
      pushItems: false,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: {north: true, east: true, south: true, west: true},
      pushResizeItems: false,
      displayGrid: this.isEditMode ? DisplayGrid.Always : DisplayGrid.None,
      disableWindowResize: true,
      allowMultiLayer: true,
      defaultLayerIndex: 2,
      itemChangeCallback: this.onWidgetChanged.bind(this),
      enableBoundaryControl: true,
    };

    this.initialized = true;
    this.listenWidgetSubs();
    if (this.isPrint) {
      this.addWidgetsToGrid(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page && this.initialized) {
      this.updatePageWidgets();
      this.addWidgetsToGrid(true);
    }
  }

  ngAfterViewInit(): void {
    window.onresize = this.listenWindowSize.bind(this);
  }

  listenWidgetSubs(): void {
    // Edit Mode Subscription
    this.subs.add(
      this.widgetService.editModeStream$.subscribe({
        next: (res: boolean): void => {
          this.isEditMode = res;
          this.options.draggable = {enabled: this.isEditMode};
          this.options.draggable.start = this.onGridItemMoving.bind(this);
          this.options.draggable.stop = this.onGridItemStopMoving.bind(this);
          this.options.resizable = {enabled: this.isEditMode};
          this.options.displayGrid = this.isEditMode
            ? DisplayGrid.Always
            : DisplayGrid.None;
          this.changedOptions();
        },
      })
    );

    // Add Widget to Grid Subscription
    this.subs.add(
      this.widgetService.addWidgetGridStream$.subscribe((widget: Widget) => {
        if (widget) {
          this.addNewWidget(widget);
        }
      })
    );

    // Remove Widget Subscription
    this.subs.add(
      this.widgetService.deleteWidgetStream$.subscribe(
        (widgetInfo: WidgetDelete) => {
          if (this.page.id === widgetInfo.pageId) {
            this.removeWidget(widgetInfo);
          }
        }
      )
    );

    // Save Widget Subscription from Editor
    this.subs.add(
      this.widgetService.saveWidgetStream$.subscribe((widgetInfo: Widget) => {
        if (this.page.id === widgetInfo.pageId) {
          this.updateWidget(widgetInfo);
        }
      })
    );

    this.subs.add(
      this.widgetService.editTitleWidgetTitle$.subscribe({
        next: (res) => {
          if (this.gridsterItems && this.gridsterItems.length > 0) {
            const idx = this.gridsterItems.findIndex(
              (el) => (el.widget as Widget).id === res.widgetId
            );

            if (idx !== undefined) {
              this.gridsterItems[idx].dragEnabled = !res.isEdit;
              this.gridsterItems[idx].resizeEnabled = !res.isEdit;
              const dragResizable = !res.isEdit && this.isEditMode;
              this.options = {
                ...this.options,
                draggable: {
                  enabled: dragResizable,
                },
                resizable: {
                  enabled: dragResizable,
                },
              };
            }
          }
        },
      })
    );
  }

  /**
   * Update the widget position when an item from the grid is changed.
   * @param item New grid item position.
   */
  onWidgetChanged(item: GridsterItem): void {
    let collision = false;
    this.page.widgets.forEach((widget: Widget, key: number) => {
      if (widget.id === item.widget.id) {
        this.page.widgets[key].position.x = item.x;
        this.page.widgets[key].position.y = item.y;
        this.page.widgets[key].position.height = item.rows;
        this.page.widgets[key].position.width = item.cols;
        item.widget.position.x = item.x;
        item.widget.position.y = item.y;
      }
    });

    collision = this.gridsterItems.some((gridsterItem: GridsterItem) => {
      if (item.widget.id === gridsterItem.widget.id) {
        return false;
      }
      return this.grid?.checkCollisionTwoItems(gridsterItem, item);
    });

    this.widgetService.onMoveWidget(item);

    this.reportService.setChangeValue = {
      hasChanges: true,
      skipPageState: collision,
    };
  }

  updateWidget(widgetInfo): void {
    this.gridsterItems.forEach((item, index) => {
      if (item.widget.id === widgetInfo.id) {
        this.gridsterItems[index] = this.getGridsterItemFromWidget(widgetInfo);
      }
    });
  }

  addWidgetsToGrid(reset = false): void {
    if (reset) {
      this.gridsterItems = [];
    }

    if (
      this.page.widgets?.every((w: Widget): boolean => typeof w === 'object')
    ) {
      this.page.widgets?.forEach((widget: Widget): void => {
        if (!Array.isArray(widget)) {
          this.gridsterItems.push(this.getGridsterItemFromWidget(widget));
        }
      });
    }
  }

  getGridsterItemFromWidget(widget: Widget): GridsterItem {
    let widgetOptions;

    if (widget.isEmpty) {
      widgetOptions = {
        cols: widget.position.width,
        rows: widget.position.height,
        y: widget.position.y,
        x: widget.position.x,
        layerIndex: widget.position.layerIndex ? widget.position.layerIndex : 2,
        widget: widget,
        dragEnabled: false,
        resizeEnabled: false,
      };
    } else {
      widgetOptions = {
        cols: widget.position.width,
        rows: widget.position.height,
        y: widget.position.y,
        x: widget.position.x,
        layerIndex: widget.position.layerIndex ? widget.position.layerIndex : 2,
        widget: widget,
      };

      if (widget.widgetType === 'branding') {
        if (this.orientation === 'vertical') {
          widgetOptions.minItemCols = 18;
          widgetOptions.minItemRows = 1;
        } else {
          widgetOptions.minItemCols = 32;
          widgetOptions.minItemRows = 1;
        }

        widgetOptions.dragEnabled = false;
      }
    }

    return widgetOptions;
  }

  /**
   * Update widget list on page changes.
   */
  private updatePageWidgets(): void {
    if (
      this.page.widgets === null ||
      (this.page.widgets.length === 1 && this.page.widgets[0].id === 'dummy')
    ) {
      this.page.widgets = [];
      this.gridsterItems = [];
      this.options?.api?.optionsChanged?.();
    }

    if (this.page.widgets.length === 0) {
      this.addEmptyWidget();
    } else {
      this.isEmpty = false;
    }
  }

  /**
   * Add an empty widget to the grid.
   */
  private addEmptyWidget(): void {
    this.isEmpty = true;
    const position: WidgetPosition =
      this.orientation === 'vertical'
        ? {y: 0, x: 0, width: 18, height: 32}
        : {y: 0, x: 0, width: 32, height: 18};

    const widget: Widget = {
      id: 'dummy',
      pageId: this.page.id,
      reportId: this.reportId,
      widgetType: 'text',
      title: '',
      isEmpty: true,
      theme: {alignItems: 'center', direction: 'column'},
      textData: {
        innerHtml: this.translate.instant(
          'reporting.report_grid.add_widget_info'
        ),
      },
      position,
    };

    this.page.widgets?.push(widget);
  }

  /**
   * Listen to window resize when is in presentation mode.
   */
  listenWindowSize(): void {
    const availableHeight = window.innerHeight;
    const availableWidth = window.innerWidth;

    if (document.fullscreenElement) {
      if (this.styleSheet) {
        window.document.head.removeChild(this.styleSheet);
        this.styleSheet = null;
      }
      this.styleSheet = document.createElement('style');
      this.styleSheet.title = 'presentationMode';
      window.document.head.appendChild(this.styleSheet);
      const styleSheetArray: CSSStyleSheet[] = Array.from(
        window.document.styleSheets
      );
      const styleSheetIndex = styleSheetArray.indexOf(
        styleSheetArray.find((sheet) => sheet.title === 'presentationMode')!
      );
      const sheet: CSSStyleSheet = window.document.styleSheets[styleSheetIndex];
      const cssRules: string[] = [
        `
          .horizontal-grid {
            max-width: unset !important;
            min-width: unset !important;
          }
        `,
        `
          .horizontal-grid {
            height: ${availableHeight}px !important;
            width: ${availableWidth}px !important;
          }
        `,
      ];

      cssRules.forEach((rule: string): void => {
        sheet.insertRule(rule, sheet.cssRules.length);
      });
    } else if (this.styleSheet) {
      window.document.head.removeChild(this.styleSheet);
      this.styleSheet = null;
    }
    this.changedOptions();
  }

  /**
   * Update grid to detect changes.
   */
  private changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  addNewWidget(newWidget: Widget): void {
    if (this.isEmpty) {
      this.grid.grid = [];
      this.gridsterItems = [];
      this.page.widgets = [];
      this.changedOptions();
    }

    this.isEditMode = true;
    const gridsterItem: GridsterItem =
      this.getGridsterItemFromWidget(newWidget);

    if (newWidget.widgetType === 'branding') {
      if (this.orientation === 'vertical') {
        gridsterItem.minItemCols = 12;
        gridsterItem.minItemRows = 1;
      } else {
        gridsterItem.minItemCols = 32;
        gridsterItem.minItemRows = 1;
      }

      gridsterItem.dragEnabled = false;

      if (newWidget?.pageWidgets && newWidget?.pageWidgets?.length > 0) {
        newWidget.pageWidgets?.forEach((widget) => {
          this.gridsterItems.forEach((item) => {
            if (item.widget.id === widget.id) {
              item.x = widget.position.x;
              item.y = widget.position.y;
              item.widget.position.x = widget.position.x;
              item.widget.position.y = widget.position.y;
            }
          });
        });

        this.page.widgets = [...newWidget.pageWidgets!];
        this.changedOptions();
      }
    }

    const arePositionsAvailable =
      this.options?.api?.getNextPossiblePosition!(gridsterItem);

    if (arePositionsAvailable || this.isEmpty) {
      this.changedOptions();
      this.gridsterItems.push(gridsterItem);

      this.gridsterItems.forEach((gi: GridsterItem): void => {
        if (newWidget.id === gi.widget.id) {
          newWidget.position.x = gi.x;
          newWidget.position.y = gi.y;
          newWidget.position.height = gi.rows;
          newWidget.position.width = gi.cols;
        }
      });

      if (newWidget.widgetType === 'branding') {
        this.pages.forEach((page: ReportPage): void => {
          if (this.page.id !== page?.id) {
            page.isCached = null;
          }
        });
      }

      this.page.widgets.push(newWidget);
      this.changedOptions();
      this.reportService.setChangeValue = {hasChanges: true};

      this.isEmpty = false;
    } else {
      this.notificationService.error(
        `The widget could not be added as it needs at least ${newWidget.position.width}x${newWidget.position.height} spaces available.`,
        5000
      );
    }
  }

  removeWidget(widgetInfo: WidgetDelete): void {
    this.gridsterItems.forEach((item: GridsterItem, index: number): void => {
      if (item.widget.id === widgetInfo.widgetId) {
        this.gridsterItems.splice(index, 1);
      }
    });
  }

  moveWidget(widgetData): void {
    const brandingItem: GridsterItem = this.gridsterItems.find(
      (gi) => gi.widget.id === widgetData.widget.id
    )!;
    const indexOfItem: number = this.gridsterItems.indexOf(brandingItem);

    switch (widgetData.position) {
      case 'top':
        this.gridsterItems[indexOfItem].y = 0;
        widgetData.widget.position.y = 0;
        this.widgetService.onMoveWidget(this.gridsterItems[indexOfItem]);
        break;
      case 'bottom':
        this.gridsterItems[indexOfItem].y = this.grid.rows - brandingItem?.rows;
        widgetData.widget.position.y = this.grid.rows - brandingItem?.rows;
        this.widgetService.onMoveWidget(this.gridsterItems[indexOfItem]);
        break;
    }

    this.changedOptions();
    this.checkGridsterItemCollision(brandingItem, widgetData.position);
  }

  checkGridsterItemCollision(gridsterItem: GridsterItem, moveTo: string): void {
    let recursive = false;
    this.gridsterItems.forEach((item) => {
      if (item.widget.id === gridsterItem.widget.id) {
        return;
      }
      const collision = this.grid.checkCollisionTwoItems(gridsterItem, item);

      if (collision) {
        const originalPosition = item.y;
        const widgetIndex =
          this.page.widgets &&
          this.page.widgets.indexOf(
            this.page?.widgets?.find(
              (widget: Widget) => widget.id === item.widget.id
            ) as Widget
          );

        if (moveTo === 'top') {
          const intersectedCells = gridsterItem.y + gridsterItem.rows - item.y;
          item.y = item.y + Math.abs(intersectedCells);
          this.page.widgets[widgetIndex].position.y = item.y;
        }

        if (moveTo === 'bottom') {
          const intersectedCells = gridsterItem.y - (item.y + item.rows);
          item.y = item.y - Math.abs(intersectedCells);
          this.page.widgets[widgetIndex].position.y = item.y;
        }

        this.changedOptions();

        if (this.grid.checkGridCollision(item)) {
          this.page.widgets[widgetIndex].position.y = originalPosition;
          this.translate
            .get('notifications.not_enough_space')
            .subscribe((res: string) => {
              this.notificationService.warning(res, 5000);
            });

          return;
        }

        recursive = true;
        this.checkGridsterItemCollision(item, moveTo);
      }
    });

    if (recursive) {
      return;
    }
    this.reportService.setChangeValue = {hasChanges: true};
  }

  onWidgetSort(widget: Widget): void {
    const sortedWidget: Widget = this.page.widgets.find(
      (item: Widget) => item.id === widget.id
    )!;
    this.page.widgets[this.page.widgets.indexOf(sortedWidget)] = widget;
  }

  onActionChanged(event: WidgetAction): void {
    this.widgetActive = event;
  }

  editWidget(event: EditWidgetConfigData): void {
    const config: MatBottomSheetConfig = {
      panelClass: 'widget-bottom-sheet',
      data: {...event, orientation: this.orientation},
    };

    const sheetRef: MatBottomSheetRef<WidgetEditorComponent> =
      this.bottomSheet.open(WidgetEditorComponent, config);

    this.reportService.setBottomSheetOpen = true;

    const sheetSub: Subscription = this.router.events.subscribe((): void => {
      sheetRef.dismiss();
    });

    sheetRef.afterDismissed().subscribe((widget): void => {
      this.reportService.setBottomSheetOpen = false;
      this.widgetService.onWidgetHasBeenEdited(widget);
      sheetSub.unsubscribe();
    });
  }

  public onGridItemMoving(item: GridsterItem): void {
    if (this.isEditMode) {
      this.isMoving = true;
      this.moveSubject.next({move: this.isMoving, widget: item});
      this.currentMove = item;
    }
  }

  public onGridItemStopMoving(item: GridsterItem): void {
    if (this.isEditMode) {
      this.isMoving = false;
      this.moveSubject.next({move: this.isMoving, widget: item});
      this.currentMove = item;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
