import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatFooterCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow } from '@angular/material/table';
import {Subscription} from 'rxjs';

import {
  ReportPage,
  ReportTheme,
  SharedAuth,
  TableColumn,
  Widget,
} from '../../../../models';
import {WidgetService} from '../../../../../core/services';
import {ColorService, HelperService, isObjectEmpty} from '../../../../helpers';
import { NgClass, NgStyle } from '@angular/common';
import { TableRowComponent } from '../../widget-settings/table-row/table-row.component';
import { CardCarouselComponent } from '../../../../mobile/components/card-carousel/card-carousel.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        NgStyle,
        MatCellDef,
        MatCell,
        TableRowComponent,
        MatFooterCellDef,
        MatFooterCell,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatFooterRowDef,
        MatFooterRow,
        CardCarouselComponent,
        TranslateModule,
    ],
})
export class TableComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  // Inputs / Output
  @Input() widget: Widget;
  @Input() widgetStyleForm: any;
  @Input() page: ReportPage;
  @Input() theme: ReportTheme = {};
  @Input() isPrint: boolean;
  @Input() isTemplate: boolean;
  @Input() reportTheme: ReportTheme;
  @Input() reportId: string;
  @Input() pageTheme: ReportTheme;
  @Input() isEditMode: boolean;
  @Input() isMobile: boolean;
  @Input() largeMobile: boolean;
  @Output() tableSortChanged = new EventEmitter();
  @Output() recordLimitReached = new EventEmitter();

  @ViewChild(MatSort, {static: false}) tableWidgetSort: MatSort;
  @ViewChild('overlayButton') myDiv: ElementRef<HTMLElement>;

  // State
  isLoading = false;

  // Properties
  subs: Subscription = new Subscription();
  tableDataSource = new MatTableDataSource<any>();
  stripeColor: string;
  displayedColumns: any[] = [];
  totalHeaderColumns: string[] = [];
  sharedAuth: SharedAuth;
  totalRow = {};
  public isObjectEmpty = isObjectEmpty;

  constructor(
    private widgetService: WidgetService,
    private colorService: ColorService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.sharedAuth = JSON.parse(sessionStorage.getItem('sharedAuth')!);
    this.isEditMode = this.widgetService.getEditMode();
    this.assignStripeColor();

    if (!this.widget?.recordsPerPage) {
      this.widget.recordsPerPage = 5;
    }

    this.assignMetricLabels();

    this.setDisplayColumns();
    this.setTableData();
  }

  ngAfterViewInit(): void {
    if (!this.isTemplate) {
      if (this.tableWidgetSort) {
        this.subs.add(
          this.tableWidgetSort.sortChange.subscribe((event: Sort): void => {
            this.widget.sortField = event.active;

            this.assignMetricLabels();
            this.setTableData();

            this.tableSortChanged.emit({
              widget: this.widget,
              type: 'sortChange',
            });
          })
        );
      }

      this.tableDataSource.sortingDataAccessor = (item, property): any => {
        return item[property].value;
      };

      if (this.displayedColumns?.includes('adPreview')) {
        if (window.document.getElementsByClassName('scaled-frame')) {
          const elements =
            window.document.getElementsByClassName('scaled-frame');

          Array.from(elements).forEach((element): void => {
            element.setAttribute('scrolling', 'no');
          });
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.setDisplayColumns();
      this.widget?.tableData!.columns.forEach((column: TableColumn): void => {
        this.widget.tableData!.rows.forEach((row): void => {
          const newKey = {[column.key.metricName]: column.field?.name};
          row = {...row, ...newKey};
        });
      });

      this.assignMetricLabels();
      this.setTableData();
      this.assignStripeColor();
      this.tableDataSource.sort = this.tableWidgetSort;
    }

    if (
      changes.widgetStyleForm !== undefined &&
      !changes.widgetStyleForm.isFirstChange() &&
      changes.widgetStyleForm.currentValue !== undefined
    ) {
      if (changes.widgetStyleForm.currentValue.type === 'style') {
        // Header Background Color
        if (
          changes.widgetStyleForm.currentValue.tableHeaderBgColor !== '' &&
          changes.widgetStyleForm.currentValue.tableHeaderBgColor !== null
        ) {
          this.theme.tableHeaderBgColor =
            changes.widgetStyleForm.currentValue.tableHeaderBgColor;
        } else if (!changes.widgetStyleForm.currentValue.tableHeaderBgColor) {
          this.theme.tableHeaderBgColor = this.pageTheme?.tableHeaderBgColor
            ? this.pageTheme.tableHeaderBgColor
            : this.reportTheme.tableHeaderBgColor;
        }

        // Header Text Color
        if (
          changes.widgetStyleForm.currentValue.tableHeaderTextColor !== '' &&
          changes.widgetStyleForm.currentValue.tableHeaderTextColor !== null
        ) {
          this.theme.tableHeaderTextColor =
            changes.widgetStyleForm.currentValue.tableHeaderTextColor;
        } else if (!changes.widgetStyleForm.currentValue.tableHeaderTextColor) {
          this.theme.tableHeaderTextColor = this.pageTheme?.tableHeaderTextColor
            ? this.pageTheme.tableHeaderTextColor
            : this.reportTheme.tableHeaderTextColor;
        }

        // Header Border Color
        if (
          changes.widgetStyleForm.currentValue.tableHeaderBorderColor !== '' &&
          changes.widgetStyleForm.currentValue.tableHeaderBorderColor !== null
        ) {
          this.theme.tableHeaderBorderColor =
            changes.widgetStyleForm.currentValue.tableHeaderBorderColor;
        } else if (
          !changes.widgetStyleForm.currentValue.tableHeaderBorderColor
        ) {
          this.theme.tableHeaderBorderColor = this.pageTheme
            ?.tableHeaderBorderColor
            ? this.pageTheme.tableHeaderBorderColor
            : this.reportTheme.tableHeaderBorderColor;
        }

        // Row Border Color
        if (
          changes.widgetStyleForm.currentValue.tableRowBorderColor !== '' &&
          changes.widgetStyleForm.currentValue.tableRowBorderColor !== null
        ) {
          this.theme.tableRowBorderColor =
            changes.widgetStyleForm.currentValue.tableRowBorderColor;
        } else if (!changes.widgetStyleForm.currentValue.tableRowBorderColor) {
          this.theme.tableRowBorderColor = this.pageTheme?.tableRowBorderColor
            ? this.pageTheme.tableRowBorderColor
            : this.reportTheme.tableRowBorderColor;
        }

        // Striped Rows
        this.theme.stripedRows =
          changes.widgetStyleForm.currentValue.stripedRows;

        // Row Background Color
        if (
          changes.widgetStyleForm.currentValue.tableRowBgColor !== '' &&
          changes.widgetStyleForm.currentValue.tableRowBgColor !== null
        ) {
          this.theme.tableRowBgColor =
            changes.widgetStyleForm.currentValue.tableRowBgColor;
          this.assignStripeColor();
        } else if (!changes.widgetStyleForm.currentValue.tableRowBgColor) {
          this.theme.tableRowBgColor = this.pageTheme?.tableRowBgColor
            ? this.pageTheme.tableRowBgColor
            : this.reportTheme.tableRowBgColor;
          this.assignStripeColor();
        }

        // Player Style
        if (changes.widgetStyleForm.currentValue?.playerStyle) {
          this.widget.playerStyle =
            changes.widgetStyleForm.currentValue.playerStyle;
        }
      }
    }
  }

  assignStripeColor(): void {
    if (this.theme.stripedRows) {
      if (this.theme.tableRowBgColor) {
        if (this.colorService.isDark(this.theme.tableRowBgColor)) {
          this.stripeColor = this.colorService.pSBC(
            0.05,
            this.theme.tableRowBgColor
          );
        } else {
          this.stripeColor = this.colorService.pSBC(
            -0.7,
            this.theme.tableRowBgColor
          );
        }
      } else {
        if (this.colorService.isDark(this.theme.backgroundColor || '')) {
          this.stripeColor = this.colorService.pSBC(
            0.05,
            this.theme.backgroundColor
          );
        } else {
          this.stripeColor = this.colorService.pSBC(
            -0.7,
            this.theme.backgroundColor
          );
        }
      }
    } else {
      this.stripeColor = this.theme.tableRowBgColor
        ? this.theme.tableRowBgColor!
        : this.theme.backgroundColor!;
    }
  }

  /**
   * This method assigns the property "valueLabel" to metrics that can have their value (number) shortened.
   * It applies the respective pipes to each value in the table.
   */
  private assignMetricLabels(): void {
    this.widget.tableData!.columns?.forEach((column: TableColumn): void => {
      if (
        column.dataType === 'currency' ||
        column.dataType === 'integer' ||
        column.dataType === 'decimal' ||
        column.dataType === 'float'
      ) {
        this.widget.tableData!.rows?.forEach((row): void => {
          row[column.key].valueLabel = this.helperService.updateMetricData(
            column.dataType,
            row[column.key].value,
            row[column.key].currency ? row[column.key].currency : null,
            column.field?.shortenNumbers
          );
        });

        // For Total Header
        if (this.widget?.tableData?.totalRow?.length > 0) {
          this.widget?.tableData?.totalRow?.forEach((row): void => {
            if (typeof row[column.key].value !== 'string') {
              row[column.key].valueLabel = this.helperService.updateMetricData(
                column.dataType,
                row[column.key].value,
                row[column.key].currency ? row[column.key].currency : null,
                column.field?.shortenNumbers
              );
            }
            if (typeof row[column.key].value === 'string') {
              row[column.key].valueLabel = row[column.key].value;
            }
          });
        }
      }
    });
  }

  private setTableData(): void {
    let totalRow: any[] =
      this.widget?.tableData?.totalRow?.length >= 0
        ? this.widget?.tableData?.totalRow
        : [];
    const tableRows: any[] = this.widget?.tableData?.rows || [];
    const firstColumn = this.widget?.tableData?.columns[0]?.key;

    if (
      this.widget?.totalPosition === 'top' ||
      this.widget?.totalPosition === 'bottom'
    ) {
      totalRow = this.setTotalRowLabel(totalRow, firstColumn);
      this.totalRow = totalRow[0] || {};
    }

    this.tableDataSource = new MatTableDataSource(tableRows);
  }

  private setTotalRowLabel(totalRow: any[], key: string): any[] {
    if (totalRow?.length > 0) {
      totalRow[0][key] = key === 'adPreview' ? 'Total' : {value: 'Total'};
    }
    return totalRow;
  }

  onRecordLimitReached(): void {
    this.recordLimitReached.emit(true);
  }

  private setDisplayColumns(): void {
    if (this.widget?.tableData?.columns) {
      this.displayedColumns = this.widget.tableData.columns.map(
        (column: TableColumn) => column.key
      );
    }

    this.totalHeaderColumns = this.displayedColumns.map(
      (column): string => column + '_'
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
