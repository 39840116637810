import {OverlayModule} from '@angular/cdk/overlay';
import {
  Component,
  inject,
  input,
  Input,
  signal,
  viewChild,
} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FontService} from '../../helpers';
import {
  MatRadioButton,
  MatRadioChange,
  MatRadioModule,
} from '@angular/material/radio';
import {FontSize} from '../../models/widget/font.model';
import {NgClass} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';

const MAT_MODULES = [MatRadioModule];

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    OverlayModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgClass,
    ...MAT_MODULES,
  ],
  selector: 'app-font-size-input',
  templateUrl: './font-size-input.component.html',
  styleUrls: ['./font-size-input.component.scss'],
})
export class FontSizeInputComponent {
  public fontSizeGroup = viewChild<MatRadioButton>('fontSizeGroup');
  fontInputType = input<'button' | 'select'>('select'); // whether the font input is for title or body
  @Input() fontSizeInput: FormControl;

  private fontSizeService = inject(FontService);

  public fontSizeSelectedControl = new FormControl();
  public customFontSizeControl = new FormControl<number | null>(null, [
    Validators.max(128),
    Validators.min(5),
  ]);
  public showFontSizeOptions = signal(false);
  public fontSizeOptions = signal(this.fontSizeService.getFontSizes);
  public isCustomFontSize = signal(false);
  public customFontSizeObject = signal<FontSize>({
    size: '',
    label: 'Custom',
    translation: 'reporting.widget_editor.font_size.custom',
  });

  constructor() {}

  onShowFontSizeMenu(): void {
    this.fontSizeInput.markAsDirty();
    this.showFontSizeOptions.update((value) => !value);
    const foundFontSize = this.fontSizeOptions().some(
      (option) => option.label === this.fontSizeInput.value.label
    );
    if (foundFontSize) {
      this.fontSizeSelectedControl.setValue(this.fontSizeInput.value);
    }
    this.isCustomFontSize.set(foundFontSize === false);
    if (this.isCustomFontSize() && this.fontSizeInput.value.size) {
      const currentFontSize = this.fontSizeInput.value.size as string;
      this.updateCustomFontSizeSizeControl(currentFontSize);
      const fontSizeToReplace = currentFontSize.replace('px', '');
      this.customFontSizeControl.setValue(parseInt(fontSizeToReplace, 10));
      this.fontSizeSelectedControl.setValue(this.customFontSizeObject());
    }
  }

  onHideFontSizeMenu(): void {
    this.showFontSizeOptions.update((value) => !value);
  }

  onApplyFontSize(): void {
    if (
      this.fontSizeSelectedControl.value.label === 'Custom' &&
      this.customFontSizeControl.valid
    ) {
      this.updateCustomFontSizeSizeControl(
        `${this.customFontSizeControl.value}px`
      );
      this.fontSizeInput.setValue(this.customFontSizeObject());
      this.onHideFontSizeMenu();
    }
    if (this.fontSizeSelectedControl.value.label !== 'Custom') {
      this.fontSizeInput.setValue(this.fontSizeSelectedControl.value);
      this.onHideFontSizeMenu();
    }
  }

  private updateCustomFontSizeSizeControl(size: string): void {
    this.customFontSizeObject.update((value) => {
      return {
        ...value,
        size,
      };
    });
  }

  onChangeSelection(event: MatRadioChange): void {
    if (event.value) {
      if (event.value.label === 'Custom') {
        this.customFontSizeControl.addValidators(Validators.required);
      }
      if (event.value.label !== 'Custom') {
        this.customFontSizeControl.clearValidators();
      }
      this.customFontSizeControl.updateValueAndValidity();
    }
  }
}
