@if (!isEditTitle || !isEditMode) {
  <section
    [ngStyle]="{'color': theme.titleColor}"
    [style.justify-content]="widget.alignment"
    [style.text-align]="
      widget.alignment === 'flex-start'
        ? 'left'
        : widget.alignment === 'flex-end'
          ? 'right'
          : 'center'
    "
    class="d-flex align-items-center h-100 w-100"
  >
    <h3
      [ngClass]="{
        'bold-text': this.theme?.labelBold === true,
        'italic-text': this.theme?.labelItalic === true,
        'mobile-heading': mobileStatus?.isMobile && !mobileStatus?.largeMobile,
      }"
    >
      <div
        [ngClass]="{
          'flex-row-reverse':
            widget.widgetIcon && widget.widgetIcon.position === 'right',
        }"
        class="d-flex gap-2 align-items-center line-height-normal"
      >
        @if (widget?.widgetIcon) {
          <i [class]="widget.widgetIcon?.faClass"></i>
        }
        {{
          widget?.isEditStyle || !widget.dynamicTitle
            ? widget?.title
            : widget.dynamicTitle
        }}
      </div>
    </h3>
  </section>
}

@if (isEditMode) {
  @if (isEditTitle) {
    <div class="edit-box">
      <input
        [cdkTrapFocusAutoCapture]="isEditTitle"
        [cdkTrapFocus]="isEditTitle"
        [formControl]="titleControl"
        type="text"
        class="wsm-input w-100"
      />
      <div class="d-flex">
        <button
          (click)="onSaveTitle()"
          class="wsm-btn wsm-btn-outline-icon wsm-btn-round-icon-xs"
        >
          <i class="fa-regular fa-floppy-disk"></i>
        </button>
        <button
          (click)="onCloseEdit()"
          class="wsm-btn wsm-btn-outline-icon wsm-btn-round-icon-xs"
        >
          <i class="fa-regular fa-xmark"></i>
        </button>
      </div>
    </div>
  }
}
