import {Injectable} from '@angular/core';
import {FontSize, TextAlignment} from '../models';

@Injectable({
  providedIn: 'root',
})
export class FontService {
  private fontSizes: Array<FontSize> = [
    {
      size: '12px',
      label: 'Small',
      translation: 'reporting.widget_editor.font_size.small',
      sizeLabel: '12',
    },
    {
      size: '16px',
      label: 'Normal',
      translation: 'reporting.widget_editor.font_size.normal',
      sizeLabel: '16',
    },
    {
      size: '20px',
      label: 'Large',
      translation: 'reporting.widget_editor.font_size.large',
      sizeLabel: '20',
    },
    {
      size: '32px',
      label: 'Huge',
      sizeLabel: '32',
      translation: 'reporting.widget_editor.font_size.huge',
    },
  ];

  private customFontSize: FontSize = {
    size: '',
    label: 'Custom',
    translation: 'reporting.widget_editor.font_size.custom',
  };

  private alignmentTypes: Array<TextAlignment> = [
    {
      label: 'Left',
      value: 'flex-start',
      translation: 'reporting.widget_editor.alignment.left',
    },
    {
      label: 'Center',
      value: 'center',
      translation: 'reporting.widget_editor.alignment.center',
    },
    {
      label: 'Right',
      value: 'flex-end',
      translation: 'reporting.widget_editor.alignment.right',
    },
  ];

  /**
   *
   * @param widgetFontSize (widgetfontSize || widget itleFontSize)
   * @returns
   */
  public findFontSize(widgetFontSize: string | undefined): FontSize | string {
    let returnFontSize: FontSize | string = '';
    let foundFontSize: FontSize | undefined;
    if (widgetFontSize) {
      foundFontSize = this.fontSizes.find(
        (fontSize) => fontSize.size === widgetFontSize
      );
      returnFontSize = foundFontSize
        ? foundFontSize
        : ({
            ...this.getCustomFontSize,
            size: widgetFontSize,
          } as FontSize);
    }
    return returnFontSize;
  }

  public get getFontSizes(): Array<FontSize> {
    return this.fontSizes;
  }

  public get getCustomFontSize(): FontSize {
    return this.customFontSize;
  }

  public get getAlignmentTypes(): Array<TextAlignment> {
    return this.alignmentTypes;
  }

  setReportFontSize(fontSize: string | undefined): void {
    const root = document.documentElement;
    root.style.setProperty('--report-fontSize', fontSize || '');
  }
}
